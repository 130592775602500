section {
	&.header {
		position: relative;;
		width: 100%;
		height: 100%;

		.slides .button {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 4em;
			margin: 0 auto;
			min-width: 190px;
		}
	}
	
	&#work-description {
		.description {
			h3 {
				@extend %section-title;
				padding-bottom: 0;
			}

			@include span(6 of 12);

			form {
				@include span(full);
			}
		}

		.people {
			@include span(5 of 12 last);
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			&:after,
			&:before {
				width: 65%;
				color: $white;
				position: absolute;
			    bottom: 0;
			    opacity: 0.8;
			    font-weight: 600;
			}

			&:before {
				margin: 3em 1.12em;
				font-size: 1em;
			}

			&:after {
				margin: 0.5em 1.12em;
				font-size: 0.95em;
			}

			&.people-1 {
				background-image: url('../images/lucha.png');

				&:before { 
					content: 'BENEFICIOS A LA COMUNIDAD';
					font-weight: 700;
				}
				&:after { content: 'La gente que vive en los bosques es beneficiada con conocimiento y capacitación.'; }
			}

			&.people-2 {
				background-image: url('../images/img-home-donacion-2.png');

				&:before { 
					content: 'CONSERVACIÓN DE BOSQUES';
					font-weight: 700;
				}
				&:after { content: 'Queremos fomentar acciones de responsabilidad social y sustentabilidad.'; }
			}

			&.people-3 {
				background-image: url('../images/img-home-donacion-3.png');

				&:before { 
					content: 'PROPUESTAS DE PROYECTOS';
					font-weight: 700;
				}
				&:after { content: 'Los jóvenes son clave para alcanzar la competitividad de los bosques y selvas.'; }
			}

			.about-people {
				@include span(65%);
				position: absolute;
				bottom: 6px;
				left: 0;
				color: $white;
				opacity: 0.8;

				p {
					margin: 0.3em 0.8em;

					&:last-of-type {
						font-size: 0.83em;
					    font-weight: 600;
					    margin: 0.3em 1em;
					}

					&.this-people {
						font-size: 0.73em;
						font-weight: 600;
					}
				}
			}
		}
	}

	&.projects {
		background-image: url('../images/wood_3.png');
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		padding: 2em 0 1em;

		.title-filters .title {
			h3 {
				padding-top: 0;
			}
			h1 {
				@extend %font-hand;
				font-size: 4em;
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&#work-description {
			.description { @include span(75%); }

			.people { @include span(28% last); }
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.header {
			.show-more {
				p { display: none; }
			}
		}

		&#work-description{
			.description { @include span(full); }
			.people {
				@include span(full);
				position: relative;
				min-height: 185px;
				background-position: 0 37%;

				&:before { margin: 4em 1.12em; }
			}
		}

		&.projects {
			background-image: url('../images/textura-arbol_mobile.png');
			background-position: center;
			background-size: 160%;
		}
	}
}