// Close button
.mfp-iframe-holder {
	.mfp-close {
		top: -2.5em;
		right: -7em;
		color: transparent;

		&:before {
			content: '\f116';
			font-family: reforestamos;
			color: $white;
			font-size: 1.2em;
		}
	}
}

/* padding-bottom and top for image */
.mfp-no-margins img.mfp-img {
	padding: 0;
	zoom: 4;
	cursor: default;
}
/* position of shadow behind the image */
.mfp-no-margins .mfp-figure:after {
	top: 0;
	bottom: 0;
}
/* padding for main container */
.mfp-no-margins .mfp-container {
	padding: 0;
}


button.mfp-arrow {
	&:before,
	&:after {
		border: none;
		font-family: reforestamos;
		font-size: 1.8em;
		color: $white;
	}

	&.mfp-arrow-left {
		&:before {
			border-right: none;
			content: '\f108';
		}
		&:after {
			content: 'Proyecto anterior';
			font-family: $font-roboto;
			font-size: 0.8em;
			width: 100px;
			margin-left: 4em;
		}
	}

	&.mfp-arrow-right {
		&:after {
			content: 'Proyecto siguiente';
			font-family: $font-roboto;
			font-size: 0.8em;
			width: 106px;
			margin-left: -5em
		}
		&:before {
			border-left: none;
			content: '\f107';
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	.mfp-iframe-holder {
		.mfp-close {
			top: -40px;
			right: -6px;
		}
	}
	button.mfp-arrow {
		display: none;
	}
}
@media all and (max-width: $mobile) {
	.mfp-iframe-holder {
		.mfp-close {
			top: -40px;
			right: -6px;
		}
	}
	button.mfp-arrow {
		display: none;
	}
}