section {
	&.form-donation {
		background-image: url('../images/wood.png');
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;

		.header .menu {
			.menu-logo .img-absolute {
				margin: 0 !important;
				a,i {
					color: $green !important;
				}
			}

			&.menu-fixed {
				padding-top: 0;

				.menu-logo {
					margin-top: 10px !important;
				}
				
				.menu-sections {
					text-align: right;
				}

				.menu-social {
					text-align: center;
				}
			}
		}

		.donation-form {
			padding-top: 10%;
			@include container;
		}

		form {
			@include span(full);

			padding-top: 3em;

			h3 {
				font-weight: 400;
			}

			hr {
				@include span(full);
				border: 1px solid $green_lemon;
			}

			.donate-to {
				@include span(8 of 12);
				@include clearfix;

				.select-field {
					@include span(4 of 8);
				}

				.currencies,
				.period-donation {
					@include clearfix;
					padding: 1.5em 0;
				}

				.currencies {
					@include span(5 of 8);

					.radio-button {
						@include span(1 of 5);

						&:last-of-type {
							@include last;
						}
					}

					.input-text {
						@include span(4 of 5);
						display: none;
					}
				}

				.period-donation {
					@include span(4 of 8);

					.select-font {
						@include span(2 of 4);

						&:last-of-type {
							@include last;
						}
					}
				}
			}

			.personal-data {
				@include span(8 of 12);
				@include clearfix;

				.common-data .input-text, 
				.phone, .birth{
					@include span(4 of 8);

					&.last { @include last; }
				}

				.phone, .birth {
					.one { @include span(1 of 4); }
				}

				.phone {
					.three { @include span(3 of 4 last); }
				}

				.birth {
					@include last;

					.select-field {
						margin-top: 0.7em;
					}

					.two { @include span(2 of 4 last); }

					p {
						color: $gray-medium;
						font-size: 0.8em;
						padding: 5px 0 0;
					}
				}
			}

			.address {
				@include span(8 of 12);
				@include clearfix;

				.row {
					@include span(full);
				}

				.input-text,
				.select-field, .address-num {
					@include span(4 of 8);

					&.last { @include last;}
				}

				.address-num .two { 
					@include span(2 of 4);

					&:last-of-type {
						@include last;
					}
				}
			}

			.taxes-wish {
				@include span(8 of 12);
				padding: 1em 0;

				.rfc-data {
					@include cubicBezier(0.41, 0.44, 0.65, 0.66);

					.row {
						@include span(full);
					}
					
					.input-text,
					.select-field {
						@include span(4 of 8);
					}

					.one {
						@include span(1 of 8);
					}

					.two {
						@include span(2 of 8);
					}

					.three {
						@include span(3 of 8);
					}

					.last {
						@include last;
					}

					&.is-visible {
						max-height: 500px;
					}
				}
			}

			.validate-data {
				@include span(full);

				padding: 1em 0;

				.select-font { @include span(6 of 12); }

				.politic { 
					@include span(3 of 12 last);

					p {
						margin: 0;
						cursor: pointer;

						&:hover {
							color: $green_lemon;
						}
					}
				}

				.aviso-privacidad { margin-top: 1em; }
			}

			.pay-way {
				@include span(8 of 12);
				@include clearfix;
				position: relative;
				padding-bottom: 2em;

				.line-sep {
					position: absolute;
					border: 1px solid $gray-medium;
					height: 65px;
					width: 1px;
					bottom: 0.6em;
					left: 0;
					right: 0;
					margin: 0 29% 0 auto;
				}

				.credit,
				.oxxo {
					h3 { 
						@include span(full); 
						text-align: center;
					}
				}

				.select-font
				label {
					img {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0.8em;
						margin: auto;
					}

					&:after {
						bottom: 4.1px;
						left: 2.7px;
					}
				}

				.credit {
					@include span(6 of 8);

					.select-font {
						@include span(2 of 6);

						&:last-of-type {
							@include last;
						}
					}
				}

				.oxxo {
					@include span(2 of 8 last);

					.select-font {
						@include span(full);

						label img {
							right: 0;
						}
					}
				}
			}

			.oxxo-rules {
				@include span(8 of 12);
				@include cubicBezier(0, 1, 0.5, 1);

				p {
					color: $gray-medium;
					margin: 0.5em 0;

					&:first-of-type {
						margin: 1em 0 0.5em;
					}
				}

				&.is-visible {
					max-height: 200px;
				}
			}

			.send {
				@include span(full);
				text-align: center;
				padding-top: 2em;

				button {
					left: 0;
					right: 0;
					margin: 0 auto;
					min-width: 180px;
				}

				p {
					text-align: justify;
					line-height: 1;
					color: $gray-medium;
				}
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.form-donation form{
			.donate-to {
				@include span(full);

				.select-field {
					@include span(8 of 12);
				}

				.currencies {
					@include span(7 of 12);

					.radio-button {
						@include span(1 of 7);
					}

					.input-text {
						@include span(5 of 7);
					}
				}

				.period-donation {
					@include span(6 of 12);
					padding: 0 0 1em;

					.select-font {
						@include span(3 of 6);

						&:last-of-type { @include last; }
					}
				}
			}

			.personal-data {
				@include span(full);

				.common-data .input-text{
					@include span(6 of 12);

					&.last { @include last; }
				}

				.phone, .birth {
					@include span(full);
				}
			}

			.address {
				@include span(full);

				.input-text,
				.select-field {
					@include span(6 of 12);

					&.last { @include last; }
				}

				.address-num {
					@include span(full);

					.two {
						@include span(6 of 12);

						&.last-of-type { @include last; }
					}
				}
			}

			.taxes-wish {
				@include span(full);

				.rfc-data {
					.input-text,
					.select-field {
						@include span(6 of 12);

						&.last { @include last; }
					}

					.one{
						@include span(2 of 12);
					}

					.three {
						@include span(4 of 12);
					}

					.two {
						@include span(6 of 12);

						&:last-of-type {
							@include last;
						}
					}

					&.is-visible {
						max-height: 791px;
					}
				}
			}

			.validate-data {
				@include span(full);

				.select-font {
					@include span(8 of 12);
				}

				.politic {
					@include span(4 of 12 last);

					p { text-align: right; }
				}
			}

			.pay-way {
				@include span(full);

				.credit, .oxxo {
					h3 {
						text-align: left;
					}
				}

				.credit {
					@include span(full);

					.select-font {
						@include span(4 of 12);

						&:last-of-type{ @include last; }
					}
				}

				.oxxo {
					@include span(full);

					.select-font {
						@include span(4 of 12);

						label img { right: 1.6em; }
					}
				}

				.line-sep { display: none; }
			}

			.oxxo-rules {
				@include span(full);

				&.is-visible {
					max-height: 181px;
				}
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.form-donation form{
			.donate-to {
				@include span(full);

				.select-field {
					@include span(8 of 12);
				}

				.currencies {
					@include span(full);

					.radio-button {
						@include span(4 of 12);
						margin-bottom: 0.5em;
					}

					.input-text {
						@include span(8 of 12);
					}
				}

				.period-donation {
					@include span(full);
					padding: 0 0 1em;

					.select-font {
						@include span(6 of 12);
					}
				}
			}

			.personal-data {
				@include span(full);

				.common-data .input-text,
				.phone, .birth {
					@include span(full);
				}
			}

			.address {
				@include span(full);

				.input-text,
				.select-field, .address-num {
					@include span(full);
				}

				.address-num .two {
					@include span(6 of 12);
				}
			}

			.taxes-wish {
				@include span(full);

				.rfc-data {
					.input-text,
					.select-field {
						@include span(full);
					}

					.one{
						@include span(4 of 12);
					}

					.three {
						@include span(8 of 12 last);
					}

					.two {
						@include span(6 of 12);

						&:last-of-type {
							@include last;
						}
					}

					&.is-visible {
						max-height: 881px;
					}
				}
			}

			.validate-data {
				@include span(full);

				.select-font {
					@include span(full);
				}

				.politic {
					@include span(6 of 12 last);

					p { text-align: right; }
				}
			}

			.pay-way {
				@include span(full);

				.credit, .oxxo {
					h3 {
						text-align: left;
					}
				}

				.credit {
					@include span(full);

					.select-font {
						@include span(4 of 12);

						label img { right: 0; }

						&:last-of-type{ @include last; }
					}
				}

				.oxxo {
					@include span(full);

					.select-font {
						@include span(4 of 12);

						label img { right: -1.4em; }
					}
				}

				.line-sep { display: none; }
			}

			.oxxo-rules {
				@include span(full);

				&.is-visible {
					max-height: 257px;
				}
			}
		}
	}
}