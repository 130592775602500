section {
	&.acknowledgments {
		clear: both;
		@include span(full);
		background-color: white;
		padding-bottom: 2em;
		padding-top: 1em;

		.title {
			@include span(full);
			color: black;
			
			h3 {
				@extend %section-title;	
				margin: 0;
			}
			p {
				margin: 0.2em 0 3em;
				font-size: 0.85em;
			}
		}

		.recognitions {
			@include span(full);

			img {
				margin-right: 2.388em;
				width: 11.5%;
				display: inline;

				&:first-of-type {
					margin-left: 5%;
				}
				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		#owl-acknowledgment {
			.owl-item .item {
				margin: 0 2em;
				display: table-cell;
			}

			&.off { display: none; }
		}

		.see-more {
			@include span(full);
			margin-top: 2em;

			.button {
				font-size: 1.3em;
				padding: 0.7em 0.9em;
				min-width: 110px;
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.acknowledgments {
			.recognitions{
				img {
					@include span(15%);

					&:first-of-type {
						margin-left: 0;
					}

					&.last { @include last; }
				}
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.acknowledgments {
			.recognitions img{ display: none; }

			#owl-acknowledgment { display: block; }
		}
	}
}
