section {
	&.contact {
		clear: both;
		background-color: $gray-medium;
		position: relative;
		padding: 2em 0;
		color: $white;

		.line-sep {
		    background-color: $gray-light;
		    position: absolute;
		    margin: 0 auto 0 49.33%;
		    height: 100%;
		    width: 1px;
		    right: 0;
		    left: 0;
		}

		h3 {
			font-weight: 100;
			margin: 0;
		}

		.more-info {
			@include span(6 of 12);

			form {
				.input-text { width: 75%; }
			}

			p {
				margin: 0 0 1em;
				font-size: 0.8em;

				a { text-decoration: underline; }
			}

			.links-contact {
				margin-top: 1.2em;
				@include span(full);
			}

			.slider {
				margin-top: 1.2em;
				@include span(full);
			}
		}

		.contact-form {
			@include span(6 of 12 last);
			padding-left: 6em;

			.select-field,
			.input-text { width: 90%; }
		}

		.more-info, .contact-form {

			form {
				.has-success:after {
					top: 17%;
					right: 1%;
				}
				span:after { top: 15%; }

				.input-captcha { 
					@include span(2 of 6);
					input { margin: 5px 0 0; }

					&.has-success:after { top: 50%; }
					&.has-error {
						span:after {
							top: initial;
							bottom: 28%;
							margin: auto;
						}
					}
				}

				button { @include span(3 of 5);}
			}
		}
	}

	&.footer {
		position: relative;
		background-color: $black;
		color: $white;

		.privacidad {
			@include span(3 of 12);
			margin-top: 0.7em;
			p { cursor: pointer; }
		}

		.social {
			@include span(3 of 12 last);
			text-align: right;

			a {
				&:hover i {
					color: $green_lemon;
				}
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.contact {
			.more-info form .input-text { width: 98%; }

			.contact-form { 
				padding-left: 1em;

				.input-text,
				.select-field { width: 98%}
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.contact {
			.line-sep { display: none; }

			.more-info, .contact-form {
				@include span(full);

				form {
					.input-captcha, button { @include span(6 of 12); }
					button { @include last; }
				}
			}

			.more-info {
				form .input-text { width: 100%; }

				.links-contact h3{ font-size: 1.4em; }
			}

			.contact-form {
				padding: 0;
				margin-top: 1.2em;

				.select-field,
				.input-text { width: 100%; }
			}
		}

		&.footer {
			.privacidad,
			.social { @include span(6 of 12); }

			.social { @include last; }
		}
	}
}