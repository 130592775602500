.team {
	@include span(full);
	text-align: center;
	
	h3 {
		font-weight: 100;
		margin-bottom: 0.4em;
	}

	p {
		margin-top: 0.4em;
		font-size: 0.9em;
	}

	.people-team {
		@include span(full);

		.person {
			text-align: center;
		    display: inline-table;
		    width: 13%;
		    margin-right: 1.5em;

		    img {
		    	max-width: 100%;
		    	max-height: 100%;
		    	display: inline;
		    }

		    span { display: block; }
		}
	}
}

@media all and (max-width: $mobile) {
	.team {
		h3 { font-size: 1.5em; }
		p { font-size: 1.1em; }

		.people-team .person {
			width: 100%;
			span { font-size: 1.1em; }
		}
	}
}
