section {
	&.work-description {
		position: relative;
		padding-bottom: 1em;

		h3 { 
			margin-top: 0;
			font-weight: 400;
		}

		.description {
			@extend %horizontal-list;
			padding-top: 1em;

			ul { padding: 0; }

			.info-work {
				li {
					padding-top: 0.5em;

					div{
						display: table-cell;
						padding: 1em 0;

						p {
							margin-top: 0.7em;
							line-height: 23px;
						}

						h3 {
							margin-bottom: 0;
						}

						&:first-of-type { vertical-align: middle; }

						&:last-of-type {
							padding-left: 1em;
							vertical-align: top;
							font-family: $font-roboto;
						}
					}
				}
			}

			form{
				.currencies {
					@include span(full);

					.radio-button { @include span(1 of 6); }

					.input-text {
						@include span(4 of 6);
						display: none;
					}
				}

				.green {
					margin: 3em 0 1em;
					min-width: 150px;
					max-width: 250px;
				}
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.work-description{
			background-position: center;
			background-size: 330%;

			.description {
				@include span(full);

				form {
					@include span(full);

					.currencies {
						.radio-button { 
							@include span(3 of 12);
							margin-bottom: 0.5em;
						}

						.input-text { @include span(6 of 12); }
					}
				}
			}
		}
	}
}
