section {
	&.header .menu {
		.content{ @include container; }

		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		right: 0;
		margin-top: 10px;

		.menu-logo, .menu-sections, .menu-social { display: inline-block; }

		.menu-logo {
			@include span(2 of 12);

			.img-absolute {
				margin: -0.6em 0;

				a i {
					color: $white;
				    font-size: 10em;
				    border-radius: 0;
				    background-color: transparent;
				    padding: 0;
				}
			}

			.img-fixed { display: none; }

			/*img {
				max-width: 100%;
				max-height: 100%;
			}*/
		}

		.menu-sections {
			@include span(8 of 12);
			text-align: right;
		}

		.menu-social {
			@include span(2 of 12 last);
			text-align: center;
			margin-left: -1em;

			i { font-size: 1.3em; }
			i:hover { color: $green_lemon; }
		}

		.menu-sections, .menu-social{
			@extend %horizontal-list;

			ul { 
				@include clearfix;
				padding: 0;
			}

			ul li {
				font-size: 0.9em;
				margin-right: 0.7128em;

				&:last-of-type { margin-right: 0; }
			}

			ul li a{
				color: $white;

				&:hover { color: $green_lemon; }

				&.active {
					color: $green_lemon;
				}
			}
		}

		.menu-button { display: none; }

		.menu-toggle {
			font-size: 0.9em;
			color: $white;
			margin-top: 1.5em;
			float: right;
			background: none;
			outline: none;
			border: none;
			display: none;
			text-align: right;

			i {
				color: $white;
				font-size: 0.9em
			}
		}

		&.green {
			margin-top: 0;
			padding-top: 10px;
			
			.menu-sections,
			.menu-social {
				ul li a {
					color: $green;

					&.active { color: $green_lemon; }

					i { color: $green; }

					&:hover {
						color: $green_lemon;

						i { color: $yellow; }
					}
				}
			}

			.menu-toggle {
				color: $green;

				i{ color: $green; }
			}
		}

		&.menu-fixed {
			position: fixed;
			background: $green_lemon;
			margin-top: 0;
			z-index: 9;

			.menu-logo {
				@include span(1 of 12);
				margin-top: 3px;

				.img-absolute { display: none; }
				.img-fixed {
					display: block;
					margin: -1.5em 0;

					a i {
						border-radius: 0;
						background-color: transparent;
						color: $black;
						font-size: 5em;
						padding: 0;
					}
				}
			}

			.menu-sections {
				@include span(8 of 12);
				//text-align: center;
				text-align: right;
				padding-right: 2em;
			}

			.menu-social {
				@include span(2 of 12);
				text-align: left;

				ul { 
					padding: 0; 

					li { margin-right: 0.5em; }
				}
			}

			.menu-sections, .menu-social{
				ul li a {
					color: $black;

					&:hover { color: $white; }

					i { 
						color: $black;

						&:hover { color: $white; }
					}

					&.active { color: $white; }
				}
			}

			.menu-button {
				@include span(20% last);
				display: block;
				background-color: $white;
				position: absolute;
				top: 0;
				right: 0;
				padding: 1px 0;
				height: 100%;
				padding-top: 0.5em;

				.button {
					margin-left: 0.5em;
					font-size: 1.276em;
					padding: 0.6em 1em 0.2em;
				}
			}

			&.infografia {
				.menu-sections, .menu-social {
					ul li a{
						&:hover { color: $white; }

						i:hover { color: $white; }

						&.active { color: $white; }
					}
				}
			}
		}

		&.infografia {
			.menu-sections, .menu-social {
				ul li a{
					color: $green;

					&:hover { color: $green_lemon; }

					i {
						color: $green;
						&:hover { color: $green_lemon; }
					}

					&.active { color: $green_lemon; }
				}
			}

			.menu-toggle {
				color: $green;
				i { color: $green; }
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.header .menu {
			.menu-logo { @include span(4 of 12); }

			.menu-sections { display: none; }

			.menu-social { 
				@include span(2 of 12);
				@include push(4);
			}

			.menu-toggle {
				@include span(2 of 12 last);
				margin-top: 1.2em;
				display: block;
			}

			&.menu-fixed {
				.menu-logo { @include span(2 of 12); }

				.menu-social {
					@include span(2 of 12);
					@include push(6);
				}

				.menu-toggle { 
					@include span(2 of 12 last);
					color: $black;
					i { color: $black; }
				}

				.menu-button { display: none; }
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.header .menu {
			.menu-logo {
				@include span(4 of 12);
				
				.img-absolute {
					margin-top: -0.53em;

					a i {
						font-size: 8em;
					}
				}
			}

			.menu-sections, .menu-social{ display: none; }

			.menu-toggle {
				@include span(5 of 12 last);
				display: block;
			}

			&.menu-fixed {
				.menu-logo {
					@include span(4 of 12);

					.img-fixed {
						margin-top: -1em;
					}
				}

				.menu-toggle {
					color: $black;
					i { color: $black; }
				}
				
				.menu-button {
					display: none;
				}
			}
		}
	}
}