@mixin clearfix{
	clear: both;
	
	&:before,
	&:after {
		content: '';
		display: table;
	}
	&:after{
		clear: both;
	}
}

@mixin cubicBezier($p1, $p2, $p3, $p4) {
	overflow-y: hidden;
	max-height: 0;

	-webkit-transition-property: all;
    -webkit-transition-duration: .5s;
    -webkit-transition-timing-function: cubic-bezier($p1, $p2, $p3, $p4);

    -moz-transition-property: all;
    -moz-transition-duration: .5s;
    -moz-transition-timing-function: cubic-bezier($p1, $p2, $p3, $p4);

    -ms-transition-property: all;
    -ms-transition-duration: .5s;
    -ms-transition-timing-function: cubic-bezier($p1, $p2, $p3, $p4);

    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier($p1, $p2, $p3, $p4);
}

@mixin centerdiv($position, $percent) { // $position = top, bottom
	position: absolute;
	#{$position}: $percent;
	left: 50%;

	-webkit-transform: translate(-50%,-50%);
	-moz-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	-o-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
}

%horizontal-list {
	ul li{
		display: inline;
		list-style: none;

		a { text-decoration: none; }
	}
}

%font-hand {
	font-family: $font-hand;
	letter-spacing: 1px;
	font-weight: 100;
}

%section-title {
	font-weight: 400;
	padding: 1em 0;
	text-transform: uppercase;
}

.row {
	@include span(full);
}

.font-roboto { font-family: $font-roboto; }

.hidden { display: none; }

.filter{
	cursor: pointer;
	padding-top: 10px;
	position: relative;
	color: inherit;
	text-decoration: none;
	line-height: 24px;

	span {
		position: relative;
		display: block;
	    width: 100px;
	    top: 6px;
	    left: 0;
	    right: 0;
	    margin: 0 auto;

		&:before, &:after {
			content: '';
			position: absolute;
			transition: transform .5s ease;
		}

		&:before {
			right: 0;
		    left: 0;
		    margin: 0 auto;
			bottom: 0;
			height: 2px;
			background: $black;
			transform:  scaleX(0);
		}
	}

	&.active span:before { transform: scaleX(1); }
	
	&:hover,
	&:active{
		span:before { transform:  scaleX(1); }
	}

	&:first-of-type span{
    	width: 50px;
    }
}

.donation {
	min-height: 50px;
	max-height: 50px;
}

.donation a.currency {
	&:hover{ color: $black; }

	&:first-child { margin: 0 0.5em 0 0; }
}

.graphic-data {
	position: relative;
	margin-top: 1em;

	.progress {
		border-radius: 9px;
		height: 32px;
		margin-bottom: 0;
		background-color: $white;
		border-color: $gray-light;
		padding: 0;

		.progress__bar {
			border-radius: 9px;

			background: rgba(250,218,56,1);
			background: -moz-linear-gradient(left, rgba(251,116,136,1) 0%, rgba(251,116,136,1) 8%, rgba(200,246,35,1) 52%, rgba(250,218,56,1) 89%, rgba(250,218,56,1) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(251,116,136,1)), color-stop(8%, rgba(251,116,136,1)), color-stop(52%, rgba(200,246,35,1)), color-stop(89%, rgba(250,218,56,1)), color-stop(100%, rgba(250,218,56,1)));
			background: -webkit-linear-gradient(left, rgba(251,116,136,1) 0%, rgba(251,116,136,1) 8%, rgba(200,246,35,1) 52%, rgba(250,218,56,1) 89%, rgba(250,218,56,1) 100%);
			background: -o-linear-gradient(left, rgba(251,116,136,1) 0%, rgba(251,116,136,1) 8%, rgba(200,246,35,1) 52%, rgba(250,218,56,1) 89%, rgba(250,218,56,1) 100%);
			background: -ms-linear-gradient(left, rgba(251,116,136,1) 0%, rgba(251,116,136,1) 8%, rgba(200,246,35,1) 52%, rgba(250,218,56,1) 89%, rgba(250,218,56,1) 100%);
			background: linear-gradient(to right, rgba(251,116,136,1) 0%, rgba(251,116,136,1) 8%, rgba(200,246,35,1) 52%, rgba(250,218,56,1) 89%, rgba(250,218,56,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb7488', endColorstr='#fada38', GradientType=1 );

			.progress__label {
				padding: 5px 8px;
				font-size: 0.9em;
				font-weight: 900;
			}

			.progress__icon {
				float: right;
				position: absolute;
				top: -2.6em;

				i {
					font-size: 2.2em;
					color: $pink;
					transform: translateX(-50%);
					-ms-transform: translateX(-50%);
	    			-webkit-transform: translateX(-50%);
					position: absolute;
				}
			}
		}
	}

	.people-graphic {
		font-size: 1.3em;

		i {
			background-color: transparent;
			border-radius: 0;
			padding: 0;
			color: $black;
			font-size: 1.5em;
		}
	}
}

.cards {
	@include span(full);
	margin-top: 1em;

	.row{
		@include span(full);

		.col {
			@include span(6 of 12);

			&.last {
				@include last;
			}
		}
	}

	.card {
		background-repeat: no-repeat;
	    background-position: center;
	    background-size: cover;
		position: relative;
		min-width: 89.5%;
		margin: 0.8em 0;
	    height: 290px;
	    z-index: 1;

		i.icon-play {
			position: absolute;
			top: 25%;
			left: 0;
			right: 0;
			width: 12%;
			margin: 0 auto;
			text-align: center;

			&:hover {
				cursor: pointer;
				color: $green_lemon;
			}
		}

		.info-card {
			color: white;
		    font-size: 0.8em;
		    position: absolute;
		    bottom: 5.5em;
		    left: 0.8em;
		    z-index: 2;

		    p {
		    	margin: 0;

		    	&:last-of-type {
		    		margin-top: 0.5em;
		    	}
		    }
		}

		.buttons {
			position: absolute;
			margin-top: -0.23em;
			padding: 0.5em;
			width: 100%;
			z-index: 2;
			bottom: 0;
			background-color: $black;

			i {
				float: left;
				font-size: 1.6em;
				margin-top: 0.358em;
			}

			.button {
				font-size: 1.3em;
			    letter-spacing: 1.5px;
			    padding: 0.8em 0em 0.6em;
			    min-width: 138px;
			    margin: 0 0.7em;

			    &.green {
			    	min-width: 175px;
			    	margin: 0;
			    }
			}
		}

		&:after {
	    	content: '\A';
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    top: 0;
		    left: 0;
		    background: -webkit-linear-gradient(to bottom, transparent, black 17em);
		    background: -moz-linear-gradient(to bottom, transparent, black 17em);
		    background: -o-linear-gradient(to bottom, transparent, black 17em);
		    background: linear-gradient(to bottom, transparent, black 17em);
		    opacity: 0.6;
	    }
	}
}

.aviso-privacidad {
	clear: both;
	@include span(9 of 12);
	@include cubicBezier(0, 1, 0.5, 1);
	max-height: 0;
	margin-top: -1em;

	p { margin-top: 0; }

	&.is-visible {
		max-height: 100px;
	}
}

.title-filters {
	@include span(full);
	margin-top: 2em;

	.title {
		@include span(3 of 12);

		h1, h3 {
			margin: 0;
		}

		h3 {
			@extend %section-title;
			padding-bottom: 0;
		}

		h1 {
			font-weight: 100;
			font-size: 3em;
		}
	}

	.projects-filters {
		@include span(9 of 12 last);

		@extend %horizontal-list;
		display: table;
		border-collapse: separate;
		border-spacing:5px;

		.sections {
			display: table-row;

			.section {
				display: table-cell;
				text-align: center;
				padding: 0.5em 0.9em;
				width: 9.3em;
				margin-bottom: 0.5em;
			}
		}
	}
}

.grid {
	@include span(full);

	.grid-item {
		.img-container {
			@include span(full);

			img {
				width: 100%;
				height: auto;
			}
		}

		.info-container {
			@include span(full);
			padding: 1.5em;

			p { font-size: 1.09em; }

			.share { font-size: 1.2em; }
		}
	}
}

.load-more {
	@include span(full);
	text-align: center;
	margin: 1.2em 0;

	a {
		left: 0;
		right: 0;
		margin: 0 auto;
		max-width: 150px;
		float: none;
	}
}

.parallax-window {
	min-height: 400px;
	background: transparent;
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	.filter {
		&.small p:before { width: 73%; }
	}

	.cards .card {
		background-position: 75%;

		i.icon-play { top: 15%; }

		.info-card { 
			bottom: 9.7em;
			right: 1.8em;
		}

		.buttons {
			@include span(full);
			text-align: center;

			i {
				@include span(2 of 12);
				position: absolute;
				top: -2%;
				left: 2%;
				font-size: 2em;
				padding: 0.204em 0.1em 0.1em 0.1em;
			}

			.links-card {
				@include span(10 of 12 last);

				.button {
					margin: 0 ;
					min-width: 130px;
					max-width: 135px;
					display: inline-block;
					@include span(5 of 10);

					&:last-of-type {
						@include last;
					}
				}
			}
		}
	}

	.title-filters {
		@include span(full);
		margin-top: 1.5em;

		.title { @include span(full); }

		.projects-filters{
			@include span(full);

			.sections{
				@include span(full);

				.section {
					padding: 0.5em 0.4em;
					@include span(2 of 10);
				}
			}
		}
	}
}

@media all and (max-width: $mobile) {
	.cards {
		.row{
			@include span(full);

			.col {
				@include span(full);

				&.last { @include span(full); }
			}
		}

		.card {
			background-position: 75%;

			i.icon-play { top: 15%; }

			.info-card { 
				bottom: 9.7em;
				right: 1.8em;
			}

			.buttons {
				@include span(full);
				text-align: center;

				i {
					@include span(3 of 12);
					position: relative;
					top: 0.7em;
					float: none;
					font-size: 2.6em;

					&.no-dona { top: 0.16em; }
				}

				.links-card {
					@include span(9 of 12 last);

					.button {
						margin: 0;

						&:last-of-type {
							clear: both;
							margin-top: 0.3em;
						}
					}
				}
			}
		}
	}

	.title-filters {
		@include span(full);
		margin-top: 1.5em;

		.title {
			@include span(full);

			h3 { 
				font-size: 1.5em;
				font-weight: 400;
			}
			h1 { font-size: 3.8em; }
			p { font-size: 1.2em; }
		}


		.projects-filters{
			@include span(full);
			
			.sections{
				@include span(full);

				.section {
					@include span(6 of 12);
					p { font-size: 1em; }

					&.last { @include last; }
				}
			}
		}
	}

	.grid {
		.grid-item .info-container { padding: 0.92em; }
	}
}
