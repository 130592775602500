<!--
////////////////////////////////////////
////           select #1              ////
////////////////////////////////////////
-->

.dropcontainer {
	position:relative;
	font-size: 16px;
	color: #777;
	} 

.trigger {
	color: #777;
	padding: 10px;
	font-size: 16px;
	width: 50%;
	background: #fff url(../images/flecha-abajo.png) 98% center no-repeat;
	display: block;
	border: 1px solid #ccc;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease; 
	transition: all 0.5s ease;
	}

.trigger:hover {
	color: #777;
	background: #f5f5f5 url(../images/flecha-abajo.png) 98% center no-repeat;
	}

.activetrigger {
	color: #777;
	padding: 10px;
	font-size: 16px;
	width: 50%;
	background: #f5f5f5 url(../images/flecha-arriba.png) 98% center no-repeat;
	display: block;
	border: 1px solid #ccc;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	}

.activetrigger:hover {
	background: #f5f5f5 url(../images/flecha-arriba.png) 98% center no-repeat;
	color: #777;
	}

.activetrigger:active {
	background: #f5f5f5 url(../images/flecha-arriba.png) 98% center no-repeat;
	color: #777;
	}

.dropcontainer ul {
	font-size: 16px;
	border: 1px solid #ccc;
	border-top: none;
	background: #fff;
	list-style-type: none;
	padding: 10px;
	margin: 0;
	width: 50%;
	z-index: 100;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	}

.dropcontainer ul li {
	padding: 5px;
	-webkit-transition: all 0.5s ease; 
	-moz-transition: all 0.5s ease; 
	-o-transition: all 0.5s ease; 
	transition: all 0.5s ease;
	}

.dropcontainer ul li:hover {
	background: #f5f5f5;
	outline: none;
	}

.dropcontainer ul li:first-child {
	display: none;
	}

.dropcontainer ul li:last-child {
	border-bottom: none;
	}

.dropdownhidden {
	display: none;
	}

.dropdownvisible {
	height: auto;
	}


<!--
////////////////////////////////////////
////           scroll #2              ////
////////////////////////////////////////
-->	


.dropcontainer-scroll {
	position:relative;
	font-size: 16px;
	color: #777;
	} 

.trigger-scroll {
	color: #777;
	padding: 10px;
	font-size: 16px;
	width: 50%;
	background: #fff url(../images/flecha-abajo.png) 98% center no-repeat;
	display: block;
	border: 1px solid #ccc;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease; 
	transition: all 0.5s ease;
	}

.trigger-scroll:hover {
	color: #777;
	background: #f5f5f5 url(../images/flecha-abajo.png) 98% center no-repeat;
	}

.activetrigger-scroll {
	color: #777;
	padding: 10px;
	font-size: 16px;
	width: 50%;
	background: #f5f5f5 url(../images/flecha-arriba.png) 98% center no-repeat;
	display: block;
	border: 1px solid #ccc;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	}

.activetrigger-scroll:hover {
	background: #f5f5f5 url(../images/flecha-arriba.png) 98% center no-repeat;
	color: #777;
	}

.activetrigger-scroll:active {
	background: #f5f5f5 url(../images/flecha-arriba.png) 98% center no-repeat;
	color: #777;
	}

.dropcontainer-scroll ul {
	font-size: 16px;
	border: 1px solid #ccc;
	border-top: none;
	background: #fff;
	list-style-type: none;
	padding: 10px;
	margin: 0;
	width: 50%;
	z-index: 100;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	}

.dropcontainer-scroll ul li {
	padding: 5px;
	-webkit-transition: all 0.5s ease; 
	-moz-transition: all 0.5s ease; 
	-o-transition: all 0.5s ease; 
	transition: all 0.5s ease;
	}

.dropcontainer-scroll ul li:hover {
	background: #f5f5f5;
	outline: none;
	}

.dropcontainer-scroll ul li:first-child {
	display: none;
	}

.dropcontainer-scroll ul li:last-child {
	border-bottom: none;
	}

.dropdownhidden-scroll {
	display: none;
	}

.dropdownvisible-scroll {
	height: auto;
	}

.dropdownvisible-scroll {
	height: 200px;
	overflow-y: scroll;
	}