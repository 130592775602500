section {
	&.share-project {
		background-color: $yellow;
		padding: 2em 0;
		clear: both;
		@include span(full);

		.container {
			text-align: center;

			h2 {
				font-weight: 100;
				margin-top: 0;
			}

			.social-buttons {
				@include span(4 of 12);
				@include push(4);

				a {
					@include span(1 of 4);
					&:first-of-type { @include push(3); }
				}
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.share-project .container {
			h2 strong { display: block; }

			.social-buttons {
				@include span(full);

				a {
					@include span(4 of 12);
					&:first-of-type { @include push(2); }
				}
			}
		}
	}
}
