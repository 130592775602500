/* Susy */
$susy: (
	columns: 12,
	container: 85%,
	global-box-sizing: border-box,
	use-custom: (rem: true)
);

$ceropx-gutters : (
    columns: 12,
    gutters: 0px/80px,
    global-box-sizing: border-box,
);

/* Fonts */
$font-roboto: Roboto, Helvetica, sans-serif;
$font-hand:   'Just Another Hand', Helvetica, sans-serif;

/* Colors */
$green: 	  	   #006935;
$green_lemon: 	   #B4DA2A;
$green_lemon-soft: #B4CE59;
$yellow: 	  	   #F9D423;
$yellow-soft: 	   #F9E274;
$pink: 		  	   #FF566F;
$black: 	  	   #000000;
$gray:   	  	   #393939;
$gray-opac: 	   rgba(125, 125, 123, 0.4);
$gray-medium: 	   #5D5D5D;
$gray-soft:   	   #7C7D7B;
$gray-light:  	   #D9D7D7;
$white: 	  	   #F5F5F5;
$error: 		   #FA6422;
$errorBck: 		   #FDD8C8;

$blue-twitter: 	   #4099FF;
$blue-facebook:    #3B5999;

$desktop: 960px;
$tablet:  768px;
$mobile:  767px;