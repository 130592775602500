@import url(https://fonts.googleapis.com/css?family=Just+Another+Hand);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);

@include border-box-sizing;

/*
  Icon Font: reforestamos
*/

@font-face {
  font-family: "reforestamos";
  src: url("../fonts/reforestamos.eot");
  src: url("../fonts/reforestamos.eot?#iefix") format("embedded-opentype"),
       url("../fonts/reforestamos.woff") format("woff"),
       url("../fonts/reforestamos.ttf") format("truetype"),
       url("../fonts/reforestamos.svg#reforestamos") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "reforestamos";
    src: url("../fonts/reforestamos.svg#reforestamos") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-blog:before,
.icon-bosques:before,
.icon-cerebro:before,
.icon-cerrar:before,
.icon-desarrollo:before,
.icon-empresa:before,
.icon-facebook:before,
.icon-flecha-abajo:before,
.icon-flecha-arriba:before,
.icon-flecha-derecha:before,
.icon-flecha-izquierda:before,
.icon-graphic-arbol:before,
.icon-instagram:before,
.icon-jovenes:before,
.icon-logo:before,
.icon-logo-menu:before,
.icon-logros-generico:before,
.icon-medios:before,
.icon-menu-mobile:before,
.icon-noticias-y-eventos:before,
.icon-persona:before,
.icon-play:before,
.icon-puentes:before,
.icon-quotes:before,
.icon-twitter:before,
.icon-upload:before {
  display: inline-block;
  font-family: "reforestamos";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-blog:before { content: "\f100"; }
.icon-bosques:before { content: "\f101"; }
.icon-cerebro:before { content: "\f118"; }
.icon-cerrar:before { content: "\f116"; }
.icon-desarrollo:before { content: "\f102"; }
.icon-empresa:before { content: "\f103"; }
.icon-facebook:before { content: "\f104"; }
.icon-flecha-abajo:before { content: "\f105"; }
.icon-flecha-arriba:before { content: "\f106"; }
.icon-flecha-derecha:before { content: "\f107"; }
.icon-flecha-izquierda:before { content: "\f108"; }
.icon-graphic-arbol:before { content: "\f115"; }
.icon-instagram:before { content: "\f109"; }
.icon-jovenes:before { content: "\f10a"; }
.icon-logo:before { content: "\f10b"; }
.icon-logo-menu:before { content: "\f117"; }
.icon-logros-generico:before { content: "\f119"; }
.icon-medios:before { content: "\f10c"; }
.icon-menu-mobile:before { content: "\f10d"; }
.icon-noticias-y-eventos:before { content: "\f10e"; }
.icon-persona:before { content: "\f10f"; }
.icon-play:before { content: "\f110"; }
.icon-puentes:before { content: "\f111"; }
.icon-quotes:before { content: "\f112"; }
.icon-twitter:before { content: "\f113"; }
.icon-upload:before { content: "\f114"; }

/*
  Icon Font: reforestamos
*/

/*@font-face {
  font-family: "reforestamos";
  src: url("../fonts/reforestamos.eot");
  src: url("../fonts/reforestamos.eot?#iefix") format("embedded-opentype"),
       url("../fonts/reforestamos.woff") format("woff"),
       url("../fonts/reforestamos.ttf") format("truetype"),
       url("../fonts/reforestamos.svg#reforestamos") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "reforestamos";
    src: url("../fonts/reforestamos.svg#reforestamos") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-blog:before,
.icon-bosques:before,
.icon-cerebro:before,
.icon-cerrar:before,
.icon-desarrollo:before,
.icon-empresa:before,
.icon-facebook:before,
.icon-flecha-abajo:before,
.icon-flecha-arriba:before,
.icon-flecha-derecha:before,
.icon-flecha-izquierda:before,
.icon-graphic-arbol:before,
.icon-instagram:before,
.icon-jovenes:before,
.icon-logo:before,
.icon-logros-generico:before,
.icon-medios:before,
.icon-menu-mobile:before,
.icon-noticias-y-eventos:before,
.icon-persona:before,
.icon-play:before,
.icon-puentes:before,
.icon-quotes:before,
.icon-twitter:before,
.icon-upload:before {
  display: inline-block;
  font-family: "reforestamos";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-blog:before { content: "\f100"; }
.icon-bosques:before { content: "\f101"; }
.icon-cerebro:before { content: "\f102"; }
.icon-cerrar:before { content: "\f103"; }
.icon-desarrollo:before { content: "\f104"; }
.icon-empresa:before { content: "\f105"; }
.icon-facebook:before { content: "\f106"; }
.icon-flecha-abajo:before { content: "\f107"; }
.icon-flecha-arriba:before { content: "\f108"; }
.icon-flecha-derecha:before { content: "\f109"; }
.icon-flecha-izquierda:before { content: "\f10a"; }
.icon-graphic-arbol:before { content: "\f10b"; }
.icon-instagram:before { content: "\f10c"; }
.icon-jovenes:before { content: "\f10d"; }
.icon-logo:before { content: "\f10e"; }
.icon-logros-generico:before { content: "\f10f"; }
.icon-medios:before { content: "\f110"; }
.icon-menu-mobile:before { content: "\f111"; }
.icon-noticias-y-eventos:before { content: "\f112"; }
.icon-persona:before { content: "\f113"; }
.icon-play:before { content: "\f114"; }
.icon-puentes:before { content: "\f115"; }
.icon-quotes:before { content: "\f116"; }
.icon-twitter:before { content: "\f117"; }
.icon-upload:before { content: "\f118"; }*/

.container {
	@include container(85%);
	@include clearfix;
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
  .container {
    @include container(95%);
  }
}