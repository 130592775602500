body { font-family: $font-roboto; }

a {
	text-decoration: none;
	color: $white;

	&:hover,
	&:active,
	&:focus {
		outline: none;
		color: $green;
	}
}

i {
	font-size: 2.8em;
	border-radius: 50%;
	padding: 0.104em 0.201em 0.02em 0.21em;
	color: $white;

	&.icon-puentes {
		background-color: $yellow;
	}

	&.icon-desarrollo,
	&.icon-medios,
	&.icon-quotes,
	&.icon-cerebro,
	&.icon-empresa {
		background-color: $green_lemon;
	}

	&.icon-bosques,
	&.icon-noticias-y-eventos,
	&.icon-logros-generico {
		background-color: $green;
	}

	&.icon-jovenes,
	&.icon-persona,
	&.icon-blog {
		background-color: $pink;
	}

	&.icon-logo {
		background-color: $black;
	}

	&.icon-flecha-izquierda,
	&.icon-flecha-derecha,
	&.icon-flecha-abajo {
		font-size: 1em;
	}
}

ul.bullet li {
	list-style-type: none;
	position: relative;

	&:before {
		content: ' ';
		position: absolute;
		left: -30px;
		top: 10px;
		width: 10px;
		height: 10px;
		background-color: $black;
		border-radius: 50%;
	}
}

img { display: block; }

small {font-size: 77%; }
