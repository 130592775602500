section {
	&.header .blog-header {
		position: relative;
		background-size: cover;
		background-position: 50% 25%;
		background-repeat: no-repeat;
		z-index: 1;

		.container {
			color: white;

			.article-extract {
				@include layout($ceropx-gutters);
				position: absolute;
				bottom: 1.5em;
				@include span(8 of 12);
				z-index: 2;

				h3 {
					@include span(full);
					margin: 12px 0;
					font-weight: 400;
					font-size: 1.4em;
				}

				h1 {
					@extend %font-hand;
					margin: 0;
					font-size: 2.92em;
					float: left;
				}

				span {
					float: left;
					padding-top: 1.4em;
					font-weight: 700;
					font-size: 0.9em;
					margin-left: 0.5em;
				}

				p {
					@include span(5 of 8);
					padding-right: 2em;
					font-size: 1em;
					line-height: 1.3em;
					margin: 8px 0 1.1em;
				}

				a {
					clear: both;
					@include span(full);
					min-width: 160px;
					max-width: 160px;
				}
			}
		}

		&:before {
	    	content: '\A';
	        position: absolute;
	        width: 100%;
	        height: 100%;
	        top: 0;
	        left: 0;
	        background: -webkit-linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
	        background: -moz-linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
	        background: -o-linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
	        background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
	        opacity: 1;
	        z-index: 2;
	    }
	}

	&.articles-and-more {
		background-image: url('../images/wood.png');
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		padding: 3em 0;
		@include span(full);

		.articles-content {
			@include span(8 of 12);

			.title-filters { margin-top: 0; }

			.projects-filters { @include span(full); }

			.articles-container {
				@include layout($ceropx-gutters);

				.grid-sizer,
				.grid-item {
					width: 45%;
					margin: 1em 2.5%;
				}

				.grid-item {
					background-color: black;
				}

				.img-container {
					position: relative;
					height: 285px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;

					&:after {
						content: '\A';
					    position: absolute;
					    width: 100%;
					    height: 100%;
					    top: 0;
					    left: 0;
					    background: -webkit-linear-gradient(to bottom, transparent, black 17em);
					    background: -moz-linear-gradient(to bottom, transparent, black 17em);
					    background: -o-linear-gradient(to bottom, transparent, black 17em);
					    background: linear-gradient(to bottom, transparent, black 17em);
					    opacity: 0.6;
					}

					img {
						height: 100%;
					}
				}
				.info-container {
					position: absolute;
					bottom: 4em;
					padding: 0 0.5693em;
					color: $white;
				}

				i {
					font-size: 1.5em;
					padding: 0.154em 0.251em 0.02em 0.21em; //0.17em 0.251em 0.09em 0.31em
				}

				.see-more {
					@include span(full);
					text-align: center;
					padding: 0.5em 0;
					position: relative;

					i {
						@include centerdiv(top, 50%);
						left: 12.5%;
					}

					a {
						left: 0;
						right: 0;
						margin: 0 auto;
						max-width: 160px;
						float: none;
					}
				}
			}
		}

		.social-donation {
			@include span(20%);
			@include push(1);

			.twitter {
				background-color: $pink;
				color: $white;
			}

			.tweets {
				padding: 1em;

				h4 { font-weight: 100; }

				ul {
					margin: 0;
					padding: 0;

					li {
						list-style: none;
						clear: both;

						img, .tweets_txt {
							float: left;
							margin-bottom: 10px;
						}

						img {
							-webkit-border-radius: 30px;
							-moz-border-radius: 30px;
							border-radius: 30px;		
						}

						.tweets_txt {
							font-size: 0.9em;

							span {
								font-style: italic;
								color: #666;
								display: block;
							}
						}
					}
				}

				.avatar {
					display: table;
					img { margin-right: 0.5em; }
					span {
						display: table-cell;
						vertical-align: middle;
					}
				}

				.tweet p {
					font-family: $font-hand;
					letter-spacing: 1.2px;
					font-size: 1.093em;
				}
			}

			.share {
				clear: both;
				padding: 1em;
				
				hr {
					height: 0px;
					border-color: transparent;
					background-color: whitesmoke;
				}

				text-align: center;
				h3 { font-weight: 100; }
				a { font-size: 2em; }
			}

			.donate {
				margin-top: 2em;
				position: relative;

				img {
					width: 100%;
					height: auto;
				}

				.title {
					position: absolute;
					bottom: 10px;
					padding: 0.5px 1.5em;
					color: $white;

					h2 {
						font-family: $font-hand;
						letter-spacing: 1.2px;
						font-size: 1.4em;
						margin: 0.5px 0;
						font-weight: 100;
					}

					p {
						font-size: 0.9em;
						margin: 0.5px 0 10px;
					}

					a {
						left: 0;
						right: 0;
						float: none;
						margin: 0 auto;
						padding: 0.5em 0.6em 0.3em;
						min-width: 100px;
						max-width: 130px;
					}
				}
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {

}

@media all and (max-width: $mobile) {
	section {
		&.header .blog-header
		.container .article-extract {
			@include span(full);
			bottom: 0.1em;

			h3 { font-size: 1em; }
			h1 { font-size: 1.5em; }

			span {
				@include span(full);
				padding-top: 0;
				font-size: 0.8em;
			}

			p {
				@include span(90%);
				font-size: 0.718em;
			}

			a {
				font-size: 1.1em;
				padding: 0.7em 0em 0.3em;
				min-width: 140px;
				max-width: 140px;
			}
		}

		&.articles-and-more {
			.articles-content {
				@include span(full);

				.articles-container{
					.grid-sizer,
					.grid-item {
						width: 100%;
						margin: 1em 0;
					}
				}

				.title-filters .projects-filters .sections .section {
					display: table;
					margin: 0;
					padding: 0;

					i {
						vertical-align: middle;
						display: table-cell;
						float: left;
						font-size: 2em;
						padding: 0.2em;
					}

					span {
						display: table-cell;
						vertical-align: middle;
						margin: 0;
						padding: 0;
						top: 0;
						font-size: 0.9em;
						line-height: 1;

						&:before { bottom: 5px;}
					}

					/*&:last-of-type {
						width: 70%;
						span {
							width: 100%;
						}
					}*/
				}
			}

			.social-donation {
				@include span(full);

				.share { padding: 0 1em;}
			}
		}
	}
}