.off-canvas {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	width: 260px;
	right: -260px;
	top: 0;
	bottom: 0;
	z-index: 9;
	background-color: $green_lemon;
	transform: translate3d(-260px, 0, 0);

	.canvas-header {
		background-color: $white;
		padding: 0.8em;
		@include span(100%);

		.row { 
			@include span(full);

			.button {
				padding: 0.5em 0.6em 0.3em;
			}

			.icon-cerrar {
				color: $gray-medium;
				font-size: 1.7em;
				float: right;

				&:hover {
					color: $gray-medium;
					cursor: pointer;
				}
			}

			i {
				color: $green_lemon;
				font-size: 1.2em;
				&:hover { color: $black; }
			}

			&:last-of-type {
				clear: both;
				margin-top: 1em;

				a { margin-right: 0.5em; }
			}
		}
	}

	.canvas-menu {
		margin-top: 6.2em;

		ul{
			list-style-type: none;
			margin-left: 0;
			padding-left: 0;

			li{
				padding: 1em;

				a { 
					color: $black;

					&:hover { color: $white; }

					&.active { color: $white; }
				}
			}
		}
	}

	&.is-open {
		visibility: visible;
		opacity: 1;
	}

	-webkit-transition: visibility 0s, opacity 0.5s linear;
	-moz-transition: visibility 0s, opacity 0.5s linear;
	-ms-transition: visibility 0s, opacity 0.5s linear;
	-o-transition: visibility 0s, opacity 0.5s linear;
	transition: visibility 0s, opacity 0.5s linear;
}

.content {
	overflow: hidden;
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {

}

@media all and (max-width: $mobile) {
	
}