section {
	&.header .conocenos-header {
		@include layout($ceropx-gutters);

		h1 {
			@extend %font-hand;
			font-size: 6em;
			margin: 0;
		}

		p {
			font-size: 1.4em;
			font-weight: 400;
			margin-top: 0;
		}

		.mision, .vision {
			@include span(6 of 12);
			height: 100%;
			position: relative;

			.container {
				position: absolute;
			    top: 50%;
			    left: 50%;
				width: 100%;
				padding: 0 3em;
				color: $white;

				-webkit-transform: translate(-50%,-50%);
				-moz-transform: translate(-50%,-50%);
				-ms-transform: translate(-50%,-50%);
				-o-transform: translate(-50%,-50%);
			    transform: translate(-50%,-50%);
			}
		}

		.mision {
			background-image: url('../images/conocenos/img-mision.png');
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;

			p { padding-top: 4em; }
		}

		.vision {
			@include last;
			background-image: url('../images/conocenos/img-vision.png');
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;

			p {
				padding-top: 6em;
			}
		}
	}

	&.time-line {
		h3 {
			@extend %section-title;
		}

		.time-line-container {
			width: 100%;
			overflow-x: auto;
			padding: 0 5em;
		}

		.line-time {
			@include layout($ceropx-gutters);
			width: 71em;
		    overflow-x: auto;
		    white-space: normal;
			height: 500px;
			position: relative;
			text-align: center;

			.middle-line {
				position: absolute;
				@include span(10 of 12);
				background-color: $green;
				height: 2px;
				top: 50%;
				left: 0;
				right: 0;
				margin: 0 auto;
				z-index: 2;
			}

			.first, .last {
				position: absolute;
				font-size: 4.1em;
			}

			.first {
				left: 0;
				top: 41%;
			}

			.last {
				top: 36.6%;
				right: 0.702em;
				padding: 0;
				border-radius: 0;
				background-color: transparent;
				color: $green;
			}

			.section {
				position: absolute;
				width: 18%;
				
				.line {
					position: absolute;
				    width: 1px;
				    height: 90%;
				    background-color: $pink;
				    left: 0;
				    right: 0;
				    margin: 0 auto;
				}

				img {
					position: relative;
					width: 40%;
					height: auto;
					left: 0;
					right: 0;
					margin: 0 auto;
				}

				h1 {
					margin: 0;
					color: $green;
				}

				p {
					font-weight: 100;
					margin: 0;
					color: $green;
					font-size: 12px;
				}

				&.down .line{ top: -7em; }

				&.up  .line {
					bottom: -4.6em;
					height: 60%;
				}

				&.one {
					width: 10%;
					bottom: 15.6%;
					left: 4%;

					.line {
						top: -3.7em;
						height: 3.5em;
					}

					img {
						width: 100%;
					}
				}

				&.two {
					width: 12%;
					left: 4%;
					top: 29.7%;

					.line {
						bottom: -2.3em;
						height: 40%;
					}
				}

				&.three {
					&.up {
						width: 12%;
						left: 12%;
						top: 1%;

						.line {
							bottom: -6.8em;
							height: 75%;
						}
					}

					&.down {
						bottom: 7%;
						width: 5%;
						left: 15.5%;

						img {
							width: 100%;
						}
					}
				}

				&.four {
					&.up {
						left: 18.5%;
						top: 28.3%;
						width: 14%;

						.line {
							bottom: -2.8em;
							height: 2.5em;
						}
					}

					&.down {
						bottom: 21%;
						left: 20.5%;
						width: 10%;

						.line {
							top: -2em;
							height: 25%;
						}

						img {
							width: 100%;
						}
					}
				}

				&.five {
					&.up {
						width: 15%;
						top: 0%;
						left: 27%;

						.line {
							bottom: -8.6em;
							height: 8.5em;
						}
						img {
							width: 33%;
						}
					}

					&.down {
						bottom: 30%;
						left: 28.4%;
						width: 12.1%;

						.line {
							top: -3em;
							height: 80%;
						}
					}
				}

				&.six {
					&.up {
						left: 37%;
						top: 26%;
						width: 14%;

						.line {
							bottom: -3.4em;
							height: 82%;
						}
					}

					&.down {
						left: 35%;
						bottom: 14%;

						.line {
							top: -8em;
							height: 7.6em;
						}
					}
				}

				&.seven {
					&.up {
						left: 45%;
						top: 0%;
						width: 15%;

						.line {
							bottom: -7.7em;
							height: 7.4em;
						}
						img {
							width: 33%;
						}
					}
					&.down {
						bottom: 33%;
						left: 46%;
						width: 13%;

						.line {
							top: -6em;
							height: 6em;
						}
					}
				}

				&.eight {
					&.up {
						width: 15%;
						left: 54%;
						top: 14.4%;

						.line {
							bottom: -2.6em;
							height: 27%;
						}

						img {
							width: 33%;
						}
					}

					&.down {
						bottom: 12%;
						left: 54%;
						width: 15%;

						.line {
							top: -10em;
							height: 9.7em;
						}
					}
				}

				&.nine {
					&.up {
						left: 64.4%;
						width: 12%;
						top: 0;

						.line {
							bottom: -8.1em;
							height: 8em;
						}
					}
					
					&.down {
						bottom: 25%;
						left: 60.5%;
						width: 12%;

						.line {
							top: -4em;
						}
					}
				}

				&.ten {
					&.up {
						left: 71%;
						width: 14%;
						top: 26%;

						.line {
							bottom: -2.5em;
							height: 48%;
						}
					}

					&.down {
						bottom: 22%;
						left: 70%;
						width: 16%;

						.line {
							top: -6.2em;
							height: 5.8em;
						}
					}
				}

				&.eleven {
					&.up {
						top: 4.3%;
						left: 78%;

						.line {
							bottom: -10.1em;
							height: 9.7em;
						}
					}

					&.down {
						bottom: 7%;
						left: 80.5%;
						width: 13%;

						.line {
							top: -11.2em;
							height: 11em;
						}
					}
				}

				&.twelve {
				    bottom: 4%;
				    left: 68%;

					.line {
						top: -7.6em;
						height: 8.3em;
					}
				}

				&.thirteen {
					bottom: 23%;
					left: 78%;

					.line {
						top: -2.8em;
						height: 60%;
					}
				}

				&.fourteen {
					left: 70.5%;
					top: 16%;

					.line {
						height: 45%;
						bottom: -3.6em;
					}
				}
			}
		}
	}

	&.our-team {
		clear: both;
		@include span(full);
		padding: 1em 0 2.5em;
		min-height: auto;
	}

	&.logros {
		@include span(full);
		background-image: url('../images/wood.png');
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: 200%;

		.logros-header {
			@include span(full);
			img {
				width: 100%;
				height: auto;
			}
		}

		h3 {
			@extend %section-title;
		}

		p {
			color: $gray-medium;
			font-size: 0.9em;
		}

		button {
			@include span(full);
			margin-bottom: 5em;
			max-width: 190px;

			&.hidden { display: none; }
		}

		.line-list {
			position: absolute;
			float: left;
			left: 0.86em;
			width: 1px;
			background: $black;
			height: 100%;
			opacity: 0.3;
		}

		.logros-lists {
			@include span(full);
			margin-bottom: 1.5em;
		}

		.first-list, .second-list {
			@include span(6 of 12);
			position: relative;

			ul.bullet li:before { top: 1.5em; }

			li {
				margin: 1em 0;
				display: inline-table;

				i {
					float: left;
					vertical-align: middle;
				}
				p {
					float: none;
					padding-left: 5em;
					strong { color: $black; }
				}

				&.hidden { display: none; }
			}
		}

		.second-list { @include last; }
	}
}

@media all and (max-width: $mobile) {
	section {
		&.header .conocenos-header {
			p {
				font-size: 0.8em;
				position: absolute;
				bottom: -3em;
			}
			h1 { font-size: 3em; }

			.mision, .vision {
				@include span(full);
				height: 50%;

				.container {
					padding: 0 3em;
				}
			}

			.mision {
				p { padding: 0; }
				h1 {
					padding-top: 1em;
				}
			} 

			.vision .container{
				top: 40%;
			}
		}

		&.time-line .time-line-container {
			padding: 0 2em;
		}

		&.logros {
			background-image: url('../images/textura-arbol_mobile.png');
			background-size: 160%;
			
			.first-list, .second-list {
				@include span(full);
			}
		}
	}
}
