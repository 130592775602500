section {
	&.more-projects {
		clear: both;
		@include span(full);
		background-color: $white;
		padding-bottom: 2em;
		padding-top: 1em;

		h3 {
			@include span(4 of 12);
			//font-weight: 100;
			@extend %section-title;
			margin: 0;
		}

		.cards .col {
			.card .info-card { 
				bottom: 7.7em;
				p { color: $white; }
			}
			
			&:last-of-type { @include last; }
		}

		p {
			@include span(full);
			margin: 0.5em 0;
			color: $gray-medium;
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.more-projects .container h3 {
			@include span(full);
			font-size: 1.5em;
		}
	}
}
