section {
	&.header .colabora-header{
		.colabora-image {
			position: relative;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;

			.container {
				position: absolute;
			    top: 60%;
			    left: 38.5%;
			    color: $white;

			    -webkit-transform: translate(-50%,-50%);
				-moz-transform: translate(-50%,-50%);
				-ms-transform: translate(-50%,-50%);
				-o-transform: translate(-50%,-50%);
			    transform: translate(-50%, -50%);

				h1 {
					@include span(full);
					@extend %font-hand;
					font-size: 2.5em;
					margin: 0.1em 0 0;
				}
				p {
					@include span(full);
					font-size: 1em;
					margin: 0;
				}
			}
		}

		.colabora-descripcion {
			position: relative;
			z-index: 3;
			background-color: $pink;
			padding: 1.2em 0 3.5em;

			.descripcion {
				@include span(6 of 12);
				@include push(3);
				text-align: center;
				color: $white;

				h1, h2, h3 {
					font-size: 1.17em;
					font-weight: 400;
				}

				p { font-size: 0.883em; }
			}
		}

		.show-more{
			bottom: 3%;

			a i {
				color: $white;

				&:hover { color: $gray-opac; }
			}
		}
	}

	&.change-colabora {
		h3 {
			clear: both;
			@extend %section-title;
		}

		.description {
			@include span(full);

			.info-work {
				@include span(full);
				
				.list {
					@include span(49%);
					margin-right: 2%;
					margin-bottom: 3%;
					display: inline-table;

					div {
						&:first-of-type {
							position: absolute;
							padding-top: 0.5em;
						}
						&:last-of-type {
							vertical-align: top;
							position: relative;
							padding-left: 5em;
						}
					}

					&.last { @include last; }
				}
			}
		}

		.form-donation-colabora{
			@include span(6 of 12);
			form{
				@include span(full);

				.currencies {
					@include span(full);

					.radio-button { @include span(1 of 6); }

					.input-text {
						@include span(4 of 6);
						display: none;
					}
				}

				.green {
					margin: 3em 0 1em;
					min-width: 150px;
					max-width: 250px;
				}
			}
		}

		.leyend-donation {
			@include span(full);
		}
	}

	&.form-volunteer {
		background-image: url('../images/wood.png');
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		clear: both;
		@include span(full);

		form {
			@include span(8 of 12);
			position: relative;

			hr {
				@include span(full);
				border: 1px solid $green_lemon;
			}

			h3 {
				font-weight: 400;
				@include span(7 of 8);

				&.first-title {
					@extend %section-title;
				}
			}

			i {
				color: $gray;
				font-size: 1.6em;
				margin: 0.5em 0;
				padding: 0;
				cursor: pointer;
				@include span(1 of 8 last);
			}

			button.green {
				clear: both;
				@include span(3 of 12);
				position: absolute;
				bottom: 0;
			}

			&.dropzone {
				background: none;
				border: none;
				min-height: 0;
				padding: 1em 0 5.5em;
			}

			.hidden-inputs {
				@include span(full);
				@include cubicBezier(0.41, 0.44, 0.65, 0.66);

				&.is-visible { max-height: 500px; }
			}

			.dz-default.dz-message {
				clear: both;
				@include span(4 of 8);
				margin: 0;
				padding: 3em 0;
				background-color: $white;
				border: 2px dashed $gray-opac;
				border-radius: 9px;

				span {
					font-family: $font-hand;
					letter-spacing: 1.2px;
					color: $gray-medium;
					position: relative;

					&:before {
						font-family: reforestamos;
						content: "\f114";
						color: $gray-medium;
						position: absolute;
						font-size: 2em;
						top: -1.3em;
						left: 0;
						right: 0;
						margin: 0 auto;
					}

					&:after {
						content: ".pdf Max. 3MB";
						font-family: $font-hand;
						letter-spacing: 1.2px;
						color: $gray-medium;
						position: absolute;
						left: 0;
						right: 0;
						margin: 0 auto;
						bottom: -20px;
					}
				}
			}

			.input-text, .select-field {
				padding: 0 0.5em;
			}

			.input-text {
				@include span(4 of 8);

				&.last { @include last; }
			}

			.radios {
				@include span(full);

				.select-font {
					@include span(3 of 8);
					
					input[type="radio"],
					input[type="checkbox"] {
						~ label {
							font-family: $font-hand;
					    	letter-spacing: 1.1px;
					    	color: $gray-medium;
					    }
					}

					&:first-of-type { @include span(2 of 8); }
					&:last-of-type { @include last; }
				}
			}

			.form-leyend { @include span(6 of 8); }


			.select-volunteer {
				@include span(full);

				.inputs {
					clear: both;
					@include span(full);

					.select-field,
					.input-text {
						@include span(4 of 8);
					}

					.input-text { @include last;}
				}
			}

			.personal-data {
				@include span(full);

				.age-input {
					@include span(2 of 8);

					label {
						float: left;
						margin-right: 0.6em;
						font-family: $font-hand;
						letter-spacing: 1.2px;
						margin: 1.8em 1em 1.8em 0
					}
				}
			}

			.address {
				@include span(full);

				.address-num {
					@include span(4 of 8);

					.input-text {
						@include span(2 of 4);
						&:last-of-type { @include last; }
					}
				}
			}
			
			.enterpriseInputs {
				.activitiesEnterprise {
					clear: both;
					@include span(full);
				}

				.date-select {
					@include span(4 of 8 last);
					padding-top: 0.8em;
					position: relative;

					label {
						position: absolute;
						top: 0;
						font-size: 1em;
						font-family: $font-hand;
						color: $gray-soft;
						letter-spacing: 1.5px;
					}

					.select-field {
						&:first-of-type { @include span(1 of 4); }
						&:last-of-type { @include span(1 of 4 last); }
						&.month { @include span(2 of 4); }
					}
				}
			}

			.applicantData {
				@include span(full);

				.data-applicant {
					clear: both;

					.phone-applicant {
						@include span(4 of 8);

						.input-text {
							&:first-of-type { @include span(1 of 4); }
							&:last-of-type { @include span(3 of 4 last); }
						}
					}
				}
			}

			.enterpriseAddress {
				@include span(full);

				.input-address-enterprise {
					clear: both;

					.input-text {
						@include span(4 of 8);

						&.last { @include last; }
					}

					.number-address {
						@include span(4 of 8);

						.input-text {
							&:first-of-type { @include span(2 of 4); }
							&:last-of-type { @include span(2 of 4 last); }
						}
					}
				}
			}

			.inputs-goberment {
				@include span(4 of 8);

				.input-text, .select-field { @include span(full); }
			}

			&.goberment-contact button { position: relative; }

			.curriculum { @include span(full); }
		}
	}

	&.colabora-contact {
		background-color: $pink;
		color: $white;
		text-align: center;
		padding-bottom: 2em;
		clear: both;
		@include span(full);

		h3 {
			@extend %section-title;
		}
	}

	&.tips-reforestamos {
		clear: both;
		@include span(full);
		padding-bottom: 1.5em;

		.tips-container {
			@include layout($ceropx-gutters);

			.grid-sizer {
				width: 30%;
				margin: 1em 1.25%;
			}

			.grid-item {
				width: 30%;
				margin: 1em 1.25%;
			}

			i.icon-persona,
			i.icon-empresa {
				padding: 0.07em 0.18em 0.02em 0.15em;
				font-size: 2.1em;
			}

			.bg-yellow {
				background-color: $yellow;
				color: $black;

				.share a i{ color: $black; }
			}
			.bg-green {
				background-color: $green;
				color: $white;
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.tips-reforestamos .tips-container {
			.grid-sizer {
				width: 45%;
				margin: 1em 2.5%;
			}

			.grid-item {
				width: 45%;
				margin: 1em 2.5%;
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.header .colabora-header{
			.colabora-image {
				.container {
					width: 100%;
					top: 60%;
					left: 50%;

					h1 { font-size: 2em; }

					p {
						@include span(full);
						font-size: 0.9em;
					}
				}
			}

			.colabora-descripcion {
				.descripcion {
					@include span(full);
					@include push(0);

					h1, h2, h3 { margin: 5px 0; }

					p { line-height: 1.5; }
				}
			}

			.show-more { display: none; }
		}

		&.change-colabora {
			.description {
				margin: 0;
				width: 100%;
				
				.info-work .list {
					@include span(full);
				}
			}

			.form-donation-colabora {
				@include span(full);
				form {
					@include span(full);

					.currencies {
						.radio-button { 
							@include span(3 of 12);
							margin-bottom: 0.5em;
						}

						.input-text { @include span(6 of 12); }
					}
				}
			}
		}

		&.form-volunteer {
			background-image: url('../images/textura-arbol_mobile.png');
			form {
				@include span(full);

				.hidden-inputs {
					&.is-visible { max-height: 600px; }
				}

				.dz-default.dz-message { @include span(full); }

				.input-text { @include span(full); }

				.radios .select-font { @include span(full); }

				.form-leyend { @include span(full); }

				.select-volunteer {
					.inputs {
						.select-field,
						.input-text { @include span(full); }
					}
				}

				.personal-data {
					.age-input {
						@include span(6 of 12);

						label { margin: 0; }
					}
				}

				.address .address-num {
					@include span(full);

					.input-text {
						@include span(6 of 12);
						&:last-of-type { @include last; }
					}
				}

				.applicantData .data-applicant  .phone-applicant {
					@include span(full);

					.input-text {
						@include span(6 of 12);
						&:last-of-type { @include last; }
					}
				}

				.enterpriseAddress .input-address-enterprise {
					.input-text { @include span(full); }

					.number-address{
						@include span(full);
						
						.input-text {
							@include span(6 of 12);
							&:last-of-type { @include last; }
						}
					}
				}

				.inputs-goberment { @include span(full); }
			}
		}

		&.tips-reforestamos {
			.title-filters .projects-filters .sections .section {
				display: table;
				margin: 0;
				padding: 0;

				i {
					vertical-align: middle;
					display: table-cell;
					float: left;
					font-size: 2em;
					padding: 0.2em;
				}

				span {
					display: table-cell;
					vertical-align: middle;
					margin: 0;
					padding: 0;
					top: 0;
					font-size: 0.9em;
					line-height: 1;

					&:before { bottom: 5px;}
				}
			}

			.tips-container {
				.grid-sizer,
				.grid-item {
					width: 90%;
					margin: 1em 5%;
				}

				.info-container .share { font-size: 1em; }
			}
		}
	}
}