section {
	&.header {
		.acknowledgment {
			position: relative;
			max-width: 100%;
			height: 100%;
			margin: 0 auto;
			background-size: cover;
			background-repeat: no-repeat;

			.container {
				position: absolute;
			    top: 0;
			    left: 0;
			    right: 0;
				margin-top: 3.5em;
				color: $white;

				-webkit-transform: translateY(50%);
				-moz-transform:    translateY(50%);
				-ms-transform:     translateY(50%);
				-o-transform:      translateY(50%);
				transform: 		   translateY(50%);

				h2 {
					font-weight: 400;
					font-size: 1.6em;
				}

				h3 {
					@include span(11 of 12);
					@extend %font-hand;
					//font-family: $font-hand;
					font-size: 3em;
					//letter-spacing: 4px;
					//font-weight: 100;
					margin: 0.1em 0 0;
				}
			}
		}
	}

	&.acknowledgments-cards {
		@include span(full);

		.img-container {
			@include span(4 of 12);
			height: 160px;
			position: relative;

			img {
				@include centerdiv(top, 50%);
			}
		}

		.text-container {
			@include span(8 of 12 last);
			padding: 2em 0;
		}
		.bg-transparent {
			@include span(full);
			background: transparent;
		}
		.bg-pink {
			@include span(full);
			background: $pink;
			color: white;
		}
	}
}

/*@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.acknowledgments-cards
		.reconocimientos-container {
			.grid-sizer,
			.grid-item {
				width: 45%;
				margin: 1em 2.5%
			}
		}
	}
}*/

@media all and (max-width: $mobile) {
	section {
		&.header
		.acknowledgment
		.container {
			margin-top: 7.5em;
			-webkit-transform: translateY(0);
			-moz-transform:    translateY(0);
			-ms-transform:     translateY(0);
			-o-transform:      translateY(0);
			transform: 		   translateY(0);

			h3 { font-size: 1.6em; }
		}

		&.acknowledgments-cards{
			.img-container,
			.text-container {
				@include span(full);
			}
		}
	}
}
