section {
	&.header .alianzas-header {
		background-image: url('../images/alianzas/alianzas_header.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		.head-text {
			@include centerdiv(top, 50%);
			left: 29%;
			color: white;

			h1 {
				@extend %font-hand;
				font-size: 2.5em;
				margin: 0;
			}
		}
	}

	&.alianzas-content {
		@include span(full);
		padding-top: 1.5em;
		padding-bottom: 1.5em;

		.alianzas-container {
			@include layout($ceropx-gutters);

			.grid-sizer {
				width: 22.5%;
				margin: 1em 1.25%;
			}

			.grid-item {
				width: 22.5%;
				margin: 1em 1.25%;

				.img-container {
					background-color: white;
				}
			}

			.bg-pink {
				background-color: $pink;
				text-align: center;
				color: white;
			}

			.grid .grid-item .info-container {
				padding: 1em 1.5em;
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.header .alianzas-header {
			.head-text {
				left: 32.3%;
			}
		}
		
		&.alianzas-content .alianzas-container {
			.grid-sizer,
			.grid-item {
				width: 45%;
				margin: 1em 2.5%;
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.header .alianzas-header {
			.head-text {
				left: 39%;
			}
		}

		&.alianzas-content .alianzas-container {
			.grid-sizer,
			.grid-item {
				width: 90%;
				margin: 1em 5%;
			}
		}
	}
}
