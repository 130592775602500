section {
	&.error {
		width: 100%;
		padding: 0 7%;
		display: table;
		margin: 0;
		max-width: none;
		height: 100vh;
		background-color: $green_lemon;

		.container {
			display: table-cell;
			vertical-align: middle;
		}

		.logo {
			@include span(2 of 12);

			img {
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
			}

			i.icon-logo {
				background-color: transparent;
				font-size: 9em;
				padding: 0;
				border-radius: 0;
				color: $green;
			}
		}

		.error-leyend {
			clear: both;
			@include span(6 of 12);
			font-family: $font-hand;

			p { font-size: 3.146em; }

			.button {
				min-width: 100px;
				padding: 0.7em 1em 0.5em;

				&:first-of-type {
					margin-right: 1em;
				}
			}
		}

		.image-error {
			@include span(6 of 12 last);

			img {
				max-width: 100%;
				max-height: 100%;
				width: 100%;
				height: 100%;
			}
		}

		.social-buttons {
			@include span(3 of 12);
			padding-top: 3em;

			i {
				color: $green;
				font-size: 1.5em;
			}
		}
	}
}