section {
	&.header {
		.slides, .slides-detail {
			.slides-container .container {
				top: 50%;
				left: 10%;
				position: absolute;
				margin-top: -9em;
				color: $white;

				h2 { font-weight: 100; }

				h1 {
					@include span(11 of 12);
					font-family: $font-hand;
					font-size: 5.8em;
					letter-spacing: 2px;
					line-height: 0.86em;
					font-weight: 100;
					margin: 0.1em 0 0;
				}

				p {
					@include span(9 of 12);
					@include clearfix;
					margin-top: 0;

					&.btn { 
						text-align: left;
						max-width: 68%;
					}
				}

				a.button {
					@include span(4 of 12); 
					clear: both;
					margin-top: 1.8em;
					font-size: 1.3em;
					letter-spacing: 2px;
					max-width: 195px;
				}

				.play-modal {
					@include span(full);
					text-align: center;
					position: absolute;
					top: 4em;

					i {
						font-size: 4.8em;
						color: $gray-light;
						cursor: pointer;

						&:hover { color: $white; }

						transition: color .25s easy-in-out;
					    -moz-transition: color .25s ease-in-out;
					    -webkit-transition: color .25s ease-in-out;
					}
				}
			}

			.leyend-image {
				@include container;
				color: $white;
				position: absolute;
				bottom: 0;
				left: 1em;
			}

			.slides-container li img {
				&.img-home-mobile {
					display: none;
				}
				&.img-home-desktop {
					display: block;
				}
			}
		}

		.slides-detail .slides-container .container {
			width: 100%;
			height: 65%;

			.play-modal {
				position: absolute;
				text-align: center;
				left: 0;
				right: 0;
				top: 30%;
				margin: 0 auto;

				a {
					position: absolute;
					left: 44%;
					z-index: 4;
				}
			}

			.title {
				position: absolute;
				bottom: 3em;

				h1 {
					@include span(full);
					font-size: 3.8em;
					margin-top: 0.4em;
				}
			}
		}

		.slides-container li:before {
	    	content: '\A';
	        position: absolute;
	        width: 100%;
	        height: 100%;
	        top: 0;
	        left: 0;
	        background: -webkit-linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
	        background: -moz-linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
	        background: -o-linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
	        background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
	        opacity: 1;
	        z-index: 2;
		}

		.slides-navigation {
			a {
				padding: 0;
				padding-top: 2px;
				margin: 0 1em;
				background-color: $gray-opac;
				border-radius: 50%;

				i {
					color: $black;
					font-size: 28px;

					&.icon-flecha-izquierda {
						margin-left: -3px;
					}

					&.icon-flecha-derecha {
						margin-right: -3px;
					}
				}

				&:hover {
					background-color: $gray;
					i {
						color: $white;
					}
				}

				transition: background-color .25s easy-in-out;
			    -moz-transition: background-color .25s ease-in-out;
			    -webkit-transition: background-color .25s ease-in-out;
			}
		}

		.slides-pagination {
			a {
				color: transparent;
				border: none;
				background-color: $black;
				border-radius: 0;
				vertical-align: bottom;
				margin: 0;

				&.current {
					background-color: $yellow;
				}
			}
		}
	}

	&.slider-detail {
		.item {
			background-size: cover;
			background-repeat: no-repeat;

			.container {
				text-align: center;
				padding: 2.2em 0;
			}

			.icon i { font-size: 1.8em; }

			.info {
				color: $white;
				h2 {
					font-family: $font-hand;
					letter-spacing: 2px;
					margin: 0;
					margin-top: 0.5em;
					font-size: 2em;
					font-weight: 400;
				}

				p {
					&:first-of-type,
					&:last-of-type { margin: 0.5em }
				}
			}
		}

		.owl-pagination { display: none; }

		.owl-buttons {
			.owl-prev,
			.owl-next { 
				i {font-size: 2em; }
			}

			.owl-prev { left: 10%; }
			.owl-next { right: 10%; }
		}
	}

	&.contact .slider,
	&.article-content .galerie {
		position: relative;
		background-color: $gray;

		.title {
			padding: 1em 1em 0;

			i {
				font-size: 1.5em;
				padding: 0.11em 0.24em;
			}
		}

		.date {
			margin-top: 0.8em;
			text-transform: uppercase;
		}

		.owl-item
		.item { padding: 0 1.5em; }

		.owl-pagination {
			position: relative;
			bottom: -4px
		}

		.owl-pagination
		.owl-page {
			&.active span { background: $yellow; }
		}

		.owl-pagination
		.owl-page span {
			border-radius: 0;
			margin: 0;
			background: $black;
			opacity: 1;
			height: 0.4em;
			width: 100%;
		}

		.owl-buttons {
			.owl-prev { left: 0; }

			.owl-next { right: 0; }
		}
	}

	&.slider-detail,
	&.article-content .galerie,
	&.contact .slider {
		.owl-buttons {
			position: absolute;
			top: 32%;
			left: 0;
			right: 0;

			.owl-prev,
			.owl-next {
				position: absolute;
				background: transparent;
				opacity: 1;
				margin: 0;
				padding: 0;
				font-size: 1em;
				border-radius: 0;
			}
		}
	}

	&.article-content .galerie {
		position: relative;
		background-color: transparent;
		height: 354px;

		.owl-carousel, .owl-wrapper-outer,
		.owl-wrapper, .owl-item, .item {
			height: 100%;
		}

		.owl-item
		.item {
			padding: 0;
		}

		.item img {
			width: 100%;
			height: 100%;
		}

		.owl-controls {
			margin-top: 0;

			.owl-pagination {
				bottom: 9px;
			}
		}

		.owl-buttons {
			top: 50%;

			i.icon-flecha-izquierda, i.icon-flecha-derecha{
				font-size: 2em;
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.header {
			.slides .slides-container .container {
				@include container(85%);

				h2 { font-size: 1.1em; }

				h1 { font-size: 4.443em; }

				p {
					@include span(full);
					text-align: left;
					max-width: 541px;
				}

				a.button {
					@include span(full);
					max-width: 172px;
					padding-left: 0.5em;
					padding-right: 0.5em;
				}
			}

			.slides-navigation { display: none; }
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.header {
			.slides .slides-container .container {
				h2 {
					@include span(full);
					font-size: 1.051em;
					span { display: block; }
				}
				h1 {
					@include span(full);
					font-size: 1.353em;
					span { display: inline; }
				}
				span.btn {
					@include span(full);
					text-align: left;
					font-size: 0.908em;
					max-width: 268px;
					span { display: block; }
				}
				a.button {
					@include span(full);
					margin-top: 1.5em;
				}
			}

			.slides .slides-container li img {
				&.img-home-mobile {
					display: block;
				}
				&.img-home-desktop {
					display: none;
				}
			}

			.slides .slides-container .leyend-image {
				display: none;
			}

			.slides-detail .slides-container .container {
				.title h1{
					@include span(full);
					font-size: 3.8em;
				}

				&.video {
					top: 50%;
					.play-modal {
						top: 6em;

						a { left: 34%; }
					}
					.title {
						top: initial;
						i { font-size: 2em; }
						h1 { font-size: 1.6em; }
					}
				}
			}

			.slides-navigation { display: none; }
		}

		&.contact .slider .info p{
			text-align: justify;
		}
	}
}