section {
	&.header .organigrama-header {
		background-image: url('../images/organigrama/organigrama_header.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;

		.head-text {
			@include centerdiv(top, 60%);
			z-index: 3;
			left: 22%;
			color: white;

			h1 {
				@extend %font-hand;
				font-size: 2.5em;
				margin: 0;
			}
		}
	}

	&.organigrama-tree {
		@include span(full);
		padding: 1.5em 0;

		img {
			width: 100%;

			&.img-mobile {
				display: none;
			}
			&.img-desktop {
				display: block;
			}
		}
	}

	&.organigrama-banner {
		@include span(full);
		background-image: url('../images/organigrama/organigrama_banner.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		height: 13em;

		.container {
			@include centerdiv(top, 40%);
			color: white;
			text-align: center;

			h1 {
				@extend %font-hand;
				font-size: 2.5em;
			}

			.button {
				@include centerdiv(bottom, -55%);
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.header .organigrama-header {
			.head-text {
				left: 32.3%;
			}
		}

		&.organigrama-banner {
			.container {
				h1 {
					font-size: 1.8em;
				}

				.button {
					bottom: -55%;
					padding: 0.5em 0 0.3em;
				}
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.header .organigrama-header {
			.head-text {
				left: 39%;
			}
		}

		&.organigrama-tree img {
			&.img-mobile {
				display: block;
			}
			&.img-desktop {
				display: none;
			}
		}

		&.organigrama-banner {
			.container{
				top: 40%;

				h1 {
					font-size: 1.7em;
				}

				.button{
					bottom: -40%;
					padding: 0.5em 0 0.3em;
				}
			}
		}
	}
}