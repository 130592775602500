section {
	&.header {
		.detail-slider {
			position: relative;
			max-width: 100%;
			height: 500px;
			margin: 0 auto;
			background-size: cover;
			background-repeat: no-repeat;
			background-position-y: -3em;
			background-position: center;

			.container {
			    position: absolute;
			    color: $white;
			    width: 100%;
				margin: 0;
			    top: 50%;
			    left: 50%;
			    margin-right: -50%;
			    
			    -webkit-transform: translate(-50%, -50%);
				-moz-transform:    translate(-50%, -50%);
				-ms-transform:     translate(-50%, -50%);
				-o-transform:      translate(-50%, -50%);
			    transform: 		   translate(-50%, -50%);

				h1 {
					@include span(11 of 12);
					@extend %font-hand;
					//font-family: $font-hand;
					font-size: 5.8em;
					//letter-spacing: 5px;
					//font-weight: 100;
					margin: 0.1em 0 0;
				}

				.play-modal {
					@include span(full);
					text-align: center;
					position: absolute;
					top: 4em;
					
					a {
						position: absolute;
						left: 44%;
						z-index: 4;
					}

					i {
						font-size: 4.8em;
						color: $gray-light;
						cursor: pointer;

						&:hover { color: $white; }

						transition: color .25s easy-in-out;
					    -moz-transition: color .25s ease-in-out;
					    -webkit-transition: color .25s ease-in-out;
					}
				}

				&.video{
					position: absolute;
					width: 100%;
					height: 65%;

					.title {
						position: absolute;
						bottom: 3em;

						h1 {
							@include span(full);
							font-size: 3.8em;
							margin-top: 0.4em;
						}
					}
				}
			}

			.show-more {
				p {
					color: $gray-soft;
				}
			}

			&:before {
		    	content: '\A';
		        position: absolute;
		        width: 100%;
		        height: 100%;
		        top: 0;
		        left: 0;
		        background: -webkit-linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
		        background: -moz-linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
		        background: -o-linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
		        background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.4) 100%);
		        opacity: 1;
		        z-index: 2;
		    }
		}

		.graphic-change {
			min-height: auto;
			
			h3 {
				@extend %section-title;
				margin: 0;

				&:last-of-type {
					margin-top: -10px;
				}
			}

			.graphic-data {
				@include span(3 of 12);
				padding-bottom: 0.5em;
			}

			.total-reached {
				@include span(5 of 12);
				margin-top: 1em;
				text-transform: uppercase;
			}

			.give-button {
				@include span(4 of 12 last);
				margin-top: 0.5em;

				.button { float: right; }
			}
		}
	}

	&.project-data {
		@include span(full);
		position: relative;

		.data-project {
			@include span(7 of 12);

			.data {
				@include span(full);
				background-color: $pink;

				.description-project {
					h1, h2, h3, h4 {
						font-size: 1.24em;
						font-weight: 400
					}

					p { line-height: 1.4em; }

					@include span(6 of 7 last);
					color: $white;
					padding: 2em 0;
					padding-right: 3em;
					text-align: justify;
				}
			}

			.google-map {
				@include span(full);
				position: relative;
				background-color: $white;

				.map-img {
					max-width: 100%;
					max-height: 100%;
				}

				h3 { font-weight: 100; }

				.ubication-project {
					@include span(6 of 7 last);
					padding: 2em 0;
					padding-right: 1em;

					.map-container {
						overflow: hidden;
						height: 300px;
						width: 100%;

						#map {
							max-width: 100%;
							max-height: 300px;
							width: 100%;
							height: 300px;
						}
					}
				}

				.ubication-address {
					position: absolute;
					bottom: 2em;
					left: 4em;
					color: $white;

					h3 {
						font-family: $font-hand;
						font-size: 2.5em;
						letter-spacing: 1.2px;
						margin: 0;
					}

					p {
						font-size: 1.7em;
						margin: 0;
					}
				}
			}
		}

		.graphic-project {
			@include span(5 of 12 last);

			.visual-data {
				@include span(4 of 5);
				padding: 2em 0 0;

				h3 {
					@extend %section-title;
					margin: 0;
				}

				ul {
					.graphic-data { margin: 2.2em 0; }
					p { font-size: 0.9em; }
				}

				.download-file ul {
					padding: 1em 0;

					li {
						display: inline-block;
						width: 100%;

						.button { max-width: 190px; }
					}
				}
			}

			.more-pages a {
				color: $black;
				text-decoration: underline;

				&:hover { color: $green_lemon; }
			}
		}
	}

	&.gallery-photo {
		clear: both;
		@include span(full);
		padding: 2em 0;

		h2 { font-weight: 100; }

		.load-more {
			@include span(full);
			padding-top: 1.2em;

			button {
				margin: 0 auto;
				float: initial;
			}
		}

		.photos-container {
			@include layout($ceropx-gutters);

			.grid-sizer {
				width: 47.5%;
				margin: 1em 1.25%;
			}

			.grid-item {
				width: 47.5%;
				margin: 1em 1.25%;
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.header .detail-slider {
				background-position-y: 0;

				.container {
					@include container(85%);
					top: 50%;

					h1 {
						margin: 0.2em 0;
						font-size: 4em;
					}

					&.video { top: 60%; }
				}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.header {
			.detail-slider {
				background-position-y: 0;

				.container {
					top: 50%;

					.icon i { font-size: 2.3em; }

					h1 { 
						font-size: 1.8em;
						margin: 1em 0;
					}
					&.video {
						top: 60%;
						.play-modal a { left: 34%; }
						.title h1 { font-size: 1.8em; }
					}
				}
			}
			.graphic-change {
				padding-top: 1em;

				.container {
					h3 { font-size: 1.5em; }
					.graphic-data { @include span(full); }
					.total-reached { @include span(full); }
					.give-button {
						@include span(full);
						float: left;
						margin-bottom: 0.8em;

						a { float: left; }
					}
				}
			}
		}

		&.project-data {
			.data-project {
				@include span(full);

				.data {
					@include span(full);
					padding: 2em;

					.description-project {
						@include span(full);
						padding: 0;
					}
				}

				.google-map .ubication-project {
					@include span(full);
					padding: 2em;

					h3 { font-size: 1.5em; }
				}
			}
			.graphic-project {
				@include span(full);

				.container .visual-data { @include span(full); }
			}
		}

		&.gallery-photo {
			.photos-container {
				@include layout($ceropx-gutters);

				.grid-sizer {
					width: 90%;
					margin: 1em 5%;
				}

				.grid-item {
					width: 90%;
					margin: 1em 5%;
				}
			}
		}
	}
}