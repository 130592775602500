@import "../bower_components/susy/sass/susy";
@import "../bower_components/font-awesome/scss/font-awesome";
@import "variables";
@import "normalize";
@import "mixins";
@import "base";
@import "interface";

/* Vendor */
@import "vendor/superslides/superslides";
@import "vendor/owl-carousel/owl-carousel";
@import "vendor/owl-carousel/owl-theme";
@import "vendor/owl-carousel/owl-transitions";
@import "vendor/formValidator/theme-default.scss";
@import "vendor/select/select";
@import "vendor/progressBar/progress";
@import "vendor/magnificPopup/magnific-popup";
@import "vendor/jqueryUI/jquery-ui";
@import "vendor/dropzone/dropzone";

/* Modules */
@import "modules/buttons";
@import "modules/form";
@import "modules/menu";
@import "modules/canvas";
@import "modules/footer";
@import "modules/slider";
@import "modules/modal";
@import "modules/error404";
@import "modules/aliances";
@import "modules/acknowledgments";
@import "modules/team-project";
@import "modules/slider-detail";
@import "modules/share-project";
@import "modules/more-projects";
@import "modules/work-description";
@import "modules/aviso-privacidad";
@import "modules/banner-organigrama";
@import "modules/header";

/* Templates */
@import "modules/home";
@import "modules/form-dona";
@import "modules/detalle";
@import "modules/gracias";
@import "modules/infografia";
@import "modules/reconocimientos";
@import "modules/colabora";
@import "modules/conocenos";
@import "modules/blog";
@import "modules/articulos";
@import "modules/organigrama";
@import "modules/alianzas";