section {
	&.aliances {
		clear: both;
		@include span(full);
		background-color: $yellow;
		position: relative;
		min-height: 253px;

		.image-aliance {
			@include span(6 of 12);
			background-image: url('../images/aliance-img.png');
			background-size: cover;
			background-position: center;
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;

			p {
				color: white;
				font-size: 0.7em;
				position: absolute;
				bottom: 0;
				right: 10px;
			}
		}

		.aliance-description {
			@include span(6 of 12 last);
			padding-left: 1.5em;
			padding-top: 3em;

			h4 {
				font-weight: 400;
				margin-bottom: 0;
			}

			p {
				margin-top: 0.5em;
				font-weight: 100;
			}

			.button {
				font-weight: 400;
				letter-spacing: 1.5px;
				font-size: 1.3em;
				min-width: 100px;
				margin-top: 0.3em;
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.aliances {
			.image-aliance {
				@include span(35.5%);
			}

			.aliance-description {
				@include span(60.5% last);
				padding-left: 0;

				h4 { margin-top: 0.3em; }

				a { margin-bottom: 0.8em; }
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.aliances {
			.image-aliance { 
				@include span(full);
				position: relative;
				height: 200px;
			}

			.aliance-description { 
				@include span(full);
				padding: 0 0 1.2em;
			}
		}
	}
}
