section {
	&.infografia {
		.container {
			@include container(100%);
			@include clearfix;
		}

		.info-image-1, .info-image-2 {
			@include span(full);
			position: relative;

			img {
				width: 100%;
				height: auto;
			}

			.imagen-mobile { display: none; }
		}

		.share-bottom, .share-top {
			left: 0;
		    position:absolute;
		    text-align:center;
		    bottom: 3em;
		    width: 100%;

			h2 {
				color: $green;
				font-size: 1.3em;
				padding: 0 9em 1em;
			}

			.buttons {
				position: relative;
				margin-bottom: 1.7em;

				.button {
					position: absolute;
					bottom: 0;
					margin: auto 51%;
					padding: 0.3em 0 0.1em;
					min-width: 160px;
					max-width: 170px;

					i { padding: 0; }

					&:first-of-type { left: 0; }
					&:last-of-type { right: 0; }
				}
			}
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.infografia{
			.share-bottom, .share-top {
				h2 {
					margin-top: -4em;
					padding: 0 8.6em 0.97em;
					font-size: 1.1em;
				}
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.infografia{
			.info-image-1, .info-image-2 {
				.imagen-desktop { display: none; }
				.imagen-mobile { display: block; }
			}

			.info-image-1 { padding-top: 2.5em; }

			.share-bottom, .share-top {
				bottom: 1em;

				h2 {
					font-size: 1.3em;
				    padding: 0 0.7em 2em;
				}

				.buttons .button {
					padding: 0.2em 0 0.1em;
					min-width: 150px;
					max-width: 90px;
					font-size: 2em;

					i { font-size: 0.7em; }
				}
			}

			.share-top h2 { font-size: 1em; }
		}
	}
}