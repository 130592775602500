form.form {
	position: relative;

	input[placeholder] { text-overflow: ellipsis; }
	::-moz-placeholder { text-overflow: ellipsis; }
	input:-moz-placeholder { text-overflow: ellipsis; }
	
	input[type="text"], input[type="email"], input[type="tel"], input[type="number"],
	select, option,
	textarea {
		display: block;
		border-radius: 9px;
		font-size: 1.2em;
		color: $white;
	    padding: 0.5em 0.8em 0.2em 0.5em;
		border: 1px solid transparent;
		margin: 1em 0;
		width: 100%;
		line-height: 1.7em;

		&::-webkit-input-placeholder {
			font-family: $font-hand;
			letter-spacing: 1px;
		}
		&:-ms-input-placeholder {
			font-family: $font-hand;
			letter-spacing: 1px;
		}
		&::-ms-input-placeholder {
			font-family: $font-hand;
			letter-spacing: 1px;
		}
		&::-moz-placeholder {
			font-family: $font-hand;
			letter-spacing: 1px;
		}
		&:-moz-placeholder {
			font-family: $font-hand;
			letter-spacing: 1px;
		}

		&:hover,
		&:focus {
			outline: none;
			border: 1px solid $green_lemon;
		}

		&:active,
		&:focus {
			&::-webkit-input-placeholder {
				color: $white;
			}
			&:-ms-input-placeholder {
				color: $white;
			}
			&::-moz-placeholder {
				color: $white;
			}
			&:-moz-placeholder {
				color: $white;
			}
		}
	}

	select {
		font-family: $font-hand;
		width: 100%;
		appearance: none;
	    -moz-appearance: none;
		-webkit-appearance: none;

		option {
			&:last-of-type {
				border-bottom-left-radius: 9px;
				border-bottom-right-radius: 9px;
			}
		}
	}

	button.button {
		float: none;
		margin: 1em 0;
		font-size: 1.3em;
		letter-spacing: 1px;
		padding: 0.8em 0.8em 0.5em;
		min-width: 100px;
		max-width: 130px;
		outline: none;
	}

	span.form-error {
		display: block;
	    color: $error;
	    margin-bottom: 0;
	    //line-height: 1;
	    font-size: 0.68em;
	    margin-top: -12px;

	    &:after {
	    	content: '!';
		    position: absolute;
		    font-family: Roboto;
		    font-size: 2.1em;
		    font-weight: 700;
		    bottom: 41%;
		    right: 3%;
	    }
	}

	select.error,
	input.error {
		background-image: none;
	}

	.input-text {
		position: relative;

		&.optional:after {
			content: '(opcional)';
			color: $gray-medium;
			position: absolute;
			font-size: 0.68em;
			bottom: 0;
		}

		&.amount{
			i {
				position: absolute;
				color: $gray-soft;
				top: 0;
				right: 0;
				cursor: pointer;
			}
			input {
				margin: 0;
				padding: 0.3em 0.8em 0.3em 1.5em;
			}

			&.has-error {
				span { margin-top: 3px; }
				&:before{
					bottom: 24px;
				}
			}

			&:before{
				font-family: $font-hand;
				content: '$';
				color: $black;
				position: absolute;
				bottom: 11px;
				left: 10px;
				font-size: 1.7em;
			}
		}

		&.has-success {
			input { background-image: none; }

			&:after {
				content: '\f00c';
				font-family: FontAwesome;
				color: $green_lemon;
				font-size: 1.3em;
				position: absolute;
				bottom: 35%;
				right: 3%;
			}
		}

		&.input-captcha.has-error {
			span.form-error {
				margin-top: 4px;
			}	
		}

		.visible {
			&.error {
				border-color: $error;
				background-color: $errorBck;
			}
		}
	}

	.select-font {
		position: relative;

		input[type="radio"],
		input[type="checkbox"] {
			border: 0;
		    clip: rect(0 0 0 0);
		    height: 1px;
		    margin: -1px;
		    overflow: hidden;
		    padding: 0;
		    position: absolute;
		    width: 1px;

		    ~ label {
		    	&:before {
		    		font-family: FontAwesome;
				    display: inline-block;
				    content: "\f111";
				    letter-spacing: 10px;
				    font-size: 1em;
				    color: $gray-medium;
				    width: 1.4em;		
		    	}
		    }

		    &:checked {
		    	~ label {
		    		&:before {
		    			content: "\f111";
					    font-size: 1em;
					    color: $gray-medium;
					    letter-spacing: 5px;			
		    		}

		    		&:after {
		    			font-family: FontAwesome;
		    			color: $green_lemon;
		    			position: absolute;
		    			content: "\f111";
		    			bottom: 5.4px;
		    			left: 2.4px;
		    			font-size: 0.6em;
		    			letter-spacing: 5px;
		    		}
		    	}
		    }
		}
		
		input[type=checkbox] {
			+ label:before {
		    	content: "\f0c8";
		    }

		    &:checked {
		    	+ label{
		    	 	&:before {
			    		content: "\f0c8";
			    	}

			    	&:after {
			    		content: "\f00c";
			    		bottom: 0;
						top: 2px;
						left: 0.5px;
						font-size: 0.8em;
			    	}
		    	}
		    }
		}

		.form-error:after {
			content: '';
		}

		&.has-error span {
			margin-top: 0;
		}
	}

	.select-field {
		position: relative;
		margin: 1em 0;

		&.has-error {
			.trigger, .trigger-scroll {
				border-color: $error;
				background-color: $errorBck;
			}

			span{
				margin-top: 0;
				&:after { content: ''; }
			}
		}
	}

	.radio-button{
	    width: 100%;
	    background-color: $white;
	    border: 1px solid $black;
	    overflow: hidden;
	    padding: 1em;

	    label {
	    	color: $black;

	    	span {
			    position: absolute;
	    		top: 0;
	    		bottom: 0;
	    		left: 0;
	    		right: 0;
	    		margin: 0 auto;
	    		padding: 0.36em 0;
	    		font-size: 1.4em;
	    		letter-spacing: 2px;

			    &:hover { cursor: pointer; }
	    	}

	    	input {
	    		position: absolute;

	    		&:checked + span {
					background-color: $green_lemon;
					color: $black;
	    		}

	    		&[type="radio"] {
	    			width: 0;
	    			opacity: 0;
	    		}
	    	}
	    }

	    &.button
	    &.currency {
	    	min-width: 70px;
	    }
	}

	.trigger, .trigger-scroll, .activetrigger, .activetrigger-scroll {
		width: 100%;
		background-size: 25px;
		border-radius: 9px;
		border: 1px solid transparent;
		font-family: $font-hand;
		text-transform: uppercase;
		margin-top: 3px;
	}

	.activetrigger, .activetrigger-scroll {
		border-bottom: transparent;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		background-color: $gray-light;
		color: $gray-medium;
	}

	.dropcontainer, .dropcontainer-scroll {
		position: relative;
		width: 100%;
		z-index: 8;
		
		ul {
			background-color: $gray-light;
			border: 1px solid transparent;
			border-top: transparent;	
			border-bottom-right-radius: 9px;
			border-bottom-left-radius: 9px;
			padding: 0;
			width: 100%;

			li {
				a {
					font-family: 'Just Another Hand';
					text-transform: uppercase;
					padding-left: 0.4em;
					color: $gray-medium;

					&:hover,
					&:active,
					&:focus {
						color: $gray-medium;
					}
				}

				&:hover {
					background: $green_lemon;
				}

				&:last-of-type {
					border-bottom-left-radius: 9px;
					border-bottom-right-radius: 9px;
				}
			}
		}
	}

	&.gray{
		textarea,
		select,
		input {
			background-color: $gray-soft;

			&::-webkit-input-placeholder {
				color: $gray-light;
			}
			&:-ms-input-placeholder {
				color: $gray-light;
			}
			&::-moz-placeholder {
				color: $gray-light;
			}
			&:-moz-placeholder {
				color: $gray-light;
			}
		}

		select {
			color: $gray-light;
		}

		.trigger {
			color: $white;
			background-color: $gray-soft;
		}
	}

	&.white {
		textarea,
		input {
			color: $gray-medium;
			background-color: white;

			&::-webkit-input-placeholder {
				color: $gray-soft;
			}
			&:-ms-input-placeholder {
				color: $gray-soft;
			}
			&::-moz-placeholder {
				color: $gray-soft;
			}
			&:-moz-placeholder {
				color: $gray-soft;
			}

			&.error {
				background: $errorBck;
			}
		}

		select {
			color: $gray-soft;
			background-color: white;
		}

		.trigger {
			background-color: white;
			color: $gray-medium;
		}
	}
}

.ui-menu { padding: 0; }

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
	font-family: $font-hand;
}

.ui-widget-content {
	border: 1px solid transparent;
	background-color: $gray-light;
	border-radius: 9px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	line-height: 1.7em;
    font-size: 1.2em;

	.ui-menu-item {			
		text-transform: uppercase;
		color: $gray-medium;
		font-family: $font-hand;
		background-color: $gray-light;

		&.ui-state-focus{
			color: $gray-medium;
			border: 1px solid transparent;
			background: $green_lemon;
		}

		&:last-of-type {
			border-bottom-left-radius: 9px;
			border-bottom-right-radius: 9px;
		}
	}
}