.header {
    .container {
        z-index: 3;
    }

    .show-more {
        @include container;
        color: $gray-light;
        text-align: center;
        position: absolute;
        z-index: 3;
        bottom: 1.2em;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 43px;

        p {
            margin: 0;
        }

        a {
            display: block;
            height: 24px;
            font-size: 2em;

            i {
                color: $gray-light;

                &:hover {
                    color: $green_lemon;
                }

                transition: color .25s easy-in-out;
                -moz-transition: color .25s ease-in-out;
                -webkit-transition: color .25s ease-in-out;
            }
        }
    }
}