section {
	&.thank-you {
		background-image: url('../images/wood.png');
		background-repeat: no-repeat;
		background-attachment: fixed;

		.thanks-content {
			padding: 7em 0 2em;

			h3 { 
				font-weight: 400;
				margin-bottom: 0;
				font-size: 1.3em
			}

			h1 {
				font-family: $font-hand;
				font-size: 6em;
				letter-spacing: 1.2px;
				font-weight: 100;
				margin: 0.2em 0 0;
			}

			.share-change {
				@include span(6 of 12);
				clear: both;
				margin: 2em 0;

				span { 
					font-size: 2em;
					vertical-align: middle;

					&:first-of-type { font-size: 1.2em; }

					a i { color: $green; }
				}
			}

			.buttons {
				@include span(6 of 12);
				clear: both;
				margin-top: 1em;

				a:first-of-type { margin-right: 1em; }
			}
		}
	}

	&.empresaria {
		background-image: url('../images/thankyou/gracias.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		padding: 4.5em 0 2.6em;

		.container {
			text-align: center;
			color: $white;

			h2 {
				font-family: $font-hand;
				font-size: 2.5em;
				letter-spacing: 2px;
				margin: 0;
			}

			p { margin: 0.5em 0; }
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {
	section {
		&.thank-you .thanks-content .buttons {
			@include span(full);
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.thank-you .thanks-content {
			h3 { font-size: 1em; }

			h1 { font-size: 4.9em; }

			.share-change { @include span(full); }

			.buttons {
				@include span(8 of 12);

				a:last-of-type { margin-top: 0.7em;}
			}
		}
	}
}