section {
	&.banner-organigrama{
		@include span(full);
		background-image: url('../images/banner_organigrama.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		height: 200px;

		.container {
			@include centerdiv(top, 50%);
			color: white;

			h1 {
				@extend %font-hand;
				font-size: 2.2em;
			}
			a {
				left: 25%;
			}
		}
	}
}

@media all and (max-width: $mobile) {
	section {
		&.banner-organigrama {
			.container a {
				left: 0;
			}
		}
	}
}