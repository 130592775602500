.btn {
	padding: 0.5em 0.6em;
    border-radius: 9px;
    float: left;

    &.yellow {
    	background-color: $yellow;
    	color: $black;
    }
}

.button {
	float: left;
    min-width: 150px;
    max-width: 250px;
    display: block;
    margin: 0;
    padding: 0.8em 1em 0.6em;
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    z-index: 3;
    border-radius: 13px;
    text-align: center;
    overflow: hidden;
    font-family: $font-hand;
    font-size: 1.3em;
    letter-spacing: 1.5px;

    -webkit-transition: color 0.3s;
    transition: color 0.3s;

    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;


    &.green {
		background: $green_lemon;
		color: $black;

        &:hover,
        &:active {
            &:before {
                background-color: $yellow-soft;
            }

            &:after {
                background-color: $yellow;
            }
        }
    }

    &.currency {
        padding: 0.55em;
        min-width: 54px;
        text-align: center;
        background-color: $white;
        border: 1px solid $black;
        margin-right: 0.5em;
        font-size: 1.4em;
        font-weight: 100;
        letter-spacing: 2px;

        &:hover,
        &:active {
            &:before {
                background-color: $green_lemon-soft;
            }

            &:after {
                background: $green_lemon;
            }
        }
    }

    &.descubrir {
        background-color: $gray;
        color: $white;

        &:hover,
        &:active {
            &:before {
                background-color: $gray-medium;
            }

            &:after {
                background: $gray;
            }
        }
    }

    &.twitter {
        background-color: $blue-twitter;
        color: $white;

        &:hover,
        &:active {
            &:before {
                background-color: $blue-twitter;
            }

            &:after {
                background: $blue-twitter;
            }
        }
    }
    
    &.facebook {
        background-color: $blue-facebook;
        color: $white;

        &:hover,
        &:active {
            &:before {
                background-color: $blue-facebook;
            }

            &:after {
                background: $blue-facebook;
            }
        }
    }

    &:before {
        background-color: transparent;
    }

    &:before,
    &:after {
    	content: '';
	    position: absolute;
    	border-radius: 13px;
    	height: 100%;
	    width: 100%;
	    bottom: 100%;
	    left: 0;
	    z-index: -1;
	    -webkit-transition: -webkit-transform 0.3s;
	    transition: transform 0.3s;
	    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    }

    &:hover,
    &:active {
    	&:before,
    	&:after {
			-webkit-transform: translate3d(0, 99%, 0);
			transform: translate3d(0, 99%, 0);
    	}

    	&:after {
			-webkit-transition-delay: 0.175s;
			transition-delay: 0.175s;
    	}
    }
}