section {
	&.header .article-detail {
		background-size: cover;
		background-position: top;
		background-repeat: no-repeat;
		height: 8em;
	}

	&.article-content {
		padding: 3em 0;
		@include span(full);

		.content-article {
			@include span(8 of 12);
			padding: 0;
			background-color: white;

			.title {
				color: white;
				padding: 1em 2em;

				h3 {
					font-weight: 100;
					font-size: 1.23em;
					margin: 0.2em 0;
				}

				h1 {
					@extend %font-hand;
					//font-weight: 100;
					//font-family: $font-hand;
					font-size: 2.6em;
					margin: 0.2em 0 0;
				}

				.date {
					font-size: 0.9em;
					font-weight: 700;
				}

				.author {
					font-size: 1em;
					padding-left: 1em;
					font-weight: 100;
				}
			}

			.img-container {
				@include span(full);
				
				img {
					width: 100%;
					height: auto;
				}
			}

			.body-article {
				@include span(full);
				padding: 1em 2em;

				h1, h2, h3, h4, h5, h6 {
					font-family: $font-hand;
					font-size: 2.5em;
					letter-spacing: 1.2px;
					font-weight: 100;
					padding-right: 5em;
					margin: 0.7em 0;
				}

				a {
					color: $black;

					&:hover{
						color: $green;
					}
				}
			}

			.video-youtube {
				@include span(full);

				iframe {
					width: 100%;
				}
			}

			.galerie {
				@include span(full);
				margin-top: 2em;
			}

			.share-article {
				@include span(full);
				margin-top: 1.5em;
				background-color: $yellow;
				color: $black;
				padding: 1em 2em;
				text-align: center;

				h2 {
					font-weight: 100;
				}

				i {
					color: $black;
				}
			}

			&.noticias-article .title { background-color: $green; }
			&.blog-article .title { background-color: $pink; }
		}

		.subscribe {
			@include span(3 of 12 last);
			position: relative;
			.img-container {
				img {
					width: 100%;
					height: auto;
				}
			}

			.text {
				position: absolute;
				bottom: 0.5em;
				padding: 0 1.2em;
				color: $white;

				p {
					padding-right: 4em;
					margin: 10px 0;
				}

				h4 {
					font-size: 1.3em;
					font-weight: 100;
					padding-right: 1em;
					margin: 0;
				}

				form.form span.form-error:after {
					bottom: 51%;
				}

				button {
					left: 0;
					right: 0;
					margin: 0 auto;
					max-width: 150px;
					min-width: 150px;
					float: none;
					padding: 0.6em 0em 0.3em;
				}
			}
		}

		.more-articles {
			@include span(full);
			padding-top: 1em;

			h3 {
				@extend %section-title;
			}

			.cards { margin-top: 0; }

			.buttons {
				.links-card {
					@include span(full);
					text-align: center;

					a.green {
						left: 0;
						right: 0;
						margin: 0 auto;
						max-width: 150px;
						min-width: 150px;
						float: none;
						padding: 0.6em 0em 0.3em;
					}
				}
			}
		}
	}

	&.more-articles {

		.cards .card .buttons i {
			margin-top: 0.15em;
		}
	}
}

@media all and (min-width: $tablet) and (max-width: $desktop - 1px) {

}

@media all and (max-width: $mobile) {
	section {
		&.article-content {
			.content-article {
				@include span(full);

				.body-article {
					h1, h2, h3, h4, h5, h6 {
						padding: 0; 
					}
				}
			}
			.subscribe {
				@include span(full);
				margin-top: 2em;

				.text {
					@include span(full);
					padding: 0 1em;
				}
			}
		}
	}
}
